<!-- eslint-disable prettier/prettier -->
<template>
  <div class="form-container">
    <form class="login">
      <span>
        ¿No tienes cuenta? <router-link to="/signup">Crea una</router-link>.
      </span>
      <h2>Inicio de sesión</h2>
      <div class="inputs">
        <input type="text" v-model="email" placeholder="Email"/>
        <input type="password" v-model="password" placeholder="Contraseña"/>
      </div>
      <span>
        <router-link to="/forgot-password">¿Has olvidado tu contraseña?</router-link>
      </span>
      <b-button @click.prevent="login" size="lg" variant="primary">
        Iniciar Sesión
      </b-button>
    </form>
    <div class="background">
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
export default {
  name: 'LoginView',
  data() {
    return {
      email: '',
      password: '',
    };
  },
  methods: {
    login() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          this.$router.push({ name: 'home' });
          console.log(firebase.auth().currentUser.uid);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-container {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  .login {
    width: 30%;
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem;
    gap: 1rem;
    h2 {
      text-align: start !important;
    }
    .inputs {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      input {
        border: none;
        border-radius: 5px;
        padding: 0.5rem 1rem;
        background-color: #f8f6f6;
      }
    }
  }
  .background {
    width: 70%;
    height: 100%;
    background-color: #1e1f72;
  }
}
</style>
