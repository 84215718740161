<!-- eslint-disable prettier/prettier -->
<template>
  <b-container fluid>
    <div class="title">
      <h1 class="header1">Soluciones</h1>
      <p class="text-content">Vanglar desarrolla herramientas
        <br />para responder a retos difíciles de superar en diversas
        <br />áreas de la empresa
      </p>
    </div>
    <div class="cards">
      <div class="card-el" v-observe-visibility="fadeIn">
        <b-img class="icon" :src="require('@/assets/home/icono-development2.png')" alt="development"></b-img>
        <h3 class="header3">Capacitación</h3>
        <p class="text-content">Soluciones enfocadas al microaprendizaje, al desarrollo de contenidos de e-learning con
          procesos de evaluación y retroalimentación inmediatos.</p>
      </div>
      <div class="card-el" v-observe-visibility="fadeIn">
        <b-img class="icon" :src="require('@/assets/home/icono-rating3.png')" alt="rating"></b-img>
        <h3 class="header3">Cumplimiento normativo (Compliance)</h3>
        <p class="text-content">Realiza procesos de KYC, aseguramiento normativo y gestión de casos de conductas no
          apropiadas de forma fácil y automática.</p>
      </div>
      <div class="card-el" v-observe-visibility="fadeIn">
        <b-img class="icon" :src="require('@/assets/home/icono-document5.png')" alt="document"></b-img>
        <h3 class="header3">Cursos</h3>
        <p class="text-content">Atendemos tus necesidades de capacitación con herramientas de planeación didáctica
          integral.</p>
      </div>
      <div class="card-el" v-observe-visibility="fadeIn">
        <b-img class="icon" :src="require('@/assets/home/icono-map1.png')" alt="map"></b-img>
        <h3 class="header3">Levantamiento de información</h3>
        <p class="text-content">Herramientas digitales que facilitan el trabajo en campo: dictado, captura de texto,
          evidencias, reportes y analíticos automatizados y en tiempo real.</p>
      </div>
      <div class="card-el" v-observe-visibility="fadeIn">
        <b-img class="icon" :src="require('@/assets/home/icono-dashboard4.png')" alt="dashboard"></b-img>
        <h3 class="header3">Analítica de datos</h3>
        <p class="text-content">Una solución para cada necesidad: desde gráficas de barras, dispersión, regresiones o
          predicciones, Vanglar cubre todas las necesidades analíticas de la organización.</p>
      </div>
    </div>
  </b-container>
</template>

<script>
export default {
  name: 'HomeCards',
  methods: {
    fadeIn(isVisible, entry) {
      if (isVisible) {
        let domElement = entry.target;
        domElement.classList.add('fadeIn');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
  max-width: 1920px;
  background: rgb(255, 255, 255);
  padding-top: 4rem;
}

.cards {
  margin-top: 4.5rem;
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-grow: 1;
  text-align: center;
  gap: 2rem;

  .card-el {
    width: 17.5%;
    padding: 1rem 1.7rem;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.17);

    .icon {
      width: 110px;
      // eslint-disable-next-line prettier/prettier
      filter: invert(44%) sepia(81%) saturate(2532%) hue-rotate(210deg)
        brightness(99%) contrast(92%);
      margin: 1.7rem 0;
    }

    p {
      font-size: 1.7rem !important;
    }
  }
}

///////////////////////////////////////
@media (max-width: 1750px) {
  .card-el {
    p {
      font-size: 1.5rem !important;
    }
  }
}

///////////////////////////////////////
@media (max-width: 1400px) {
  .cards {
    margin-top: 3rem;
    text-align: center;
    gap: 3rem;

    .card-el {
      width: 25%;

      .icon {
        width: 80px;
      }
    }
  }
}

///////////////////////////////////////
@media (max-width: 1100px) {
  .cards {
    gap: 1.5rem;

    .card-el {
      width: 30%;

      p {
        font-size: 1.3rem !important;
      }
    }
  }
}

///////////////////////////////////////
@media (max-width: 900px) {
  .cards {
    gap: 2.5rem;

    .card-el {
      width: 40%;
    }
  }
}

///////////////////////////////////////
@media (max-width: 767px) {
  .cards {
    .card-el {
      p {
        font-size: 1.2rem !important;
      }
    }
  }
}

///////////////////////////////////////
@media (max-width: 604px) {
  .title {
    width: 100%;
    //AGREGAR ALGO
  }

  .cards {
    gap: 2rem;

    .card-el {
      width: 80%;
    }
  }
}

///////////////////////////////////////
@media (max-width: 470px) {
  .cards {
    .card-el {
      width: 90%;

      p {
        font-size: 1.1rem !important;
      }
    }
  }
}
</style>
