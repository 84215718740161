/* eslint-disable prettier/prettier */
import Vue from 'vue';
import Vuex from 'vuex';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import db from '../assets/firebase/firebaseInit';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    blogPosts: [],
    visibleBlogPosts: [],
    blogCategories: [],
    postLoaded: null,
    user: null,
    profileAdmin: null,
    profileEmail: null,
    profileFirstName: null,
    profileLastName: null,
    profileUsername: null,
    profileId: null,
    profileInitials: null,
    blogHTML: '',
    blogTitle: '',
    blogTags: [],
    blogAuthor: '',
    blogCategory: '',
    blogShortDescription: '',
    blogPublished: null,
    blogPhotoName: '',
    blogPhotoFileURL: null,
    blogPhotoPreview: null,
    editPost: null,
  },
  getters: {
    blogFeaturedCard(state) {
      return state.visibleBlogPosts.slice(0, 1);
    },
    blogPostsCards(state) {
      return state.visibleBlogPosts.slice(1, 1000);
    },
  },
  mutations: {
    newBlogPost(state, payload) {
      state.blogHTML = payload;
    },
    updateBlogTitle(state, payload) {
      state.blogTitle = payload;
    },
    updateBlogTags(state, payload) {
      state.blogTags = payload;
    },
    updateBlogAuthor(state, payload) {
      state.blogAuthor = payload;
    },
    updateBlogCategory(state, payload) {
      state.blogCategory = payload;
    },
    updateBlogShortDescription(state, payload) {
      state.blogShortDescription = payload;
    },
    updateBlogPublished(state, payload) {
      state.blogPublished = payload;
    },
    fileNameChange(state, payload) {
      state.blogPhotoName = payload;
    },
    createFileURL(state, payload) {
      state.blogPhotoFileURL = payload;
    },
    openPhotoPreview(state) {
      state.blogPhotoPreview = !state.blogPhotoPreview;
    },
    toggleEditPost(state, payload) {
      state.editPost = payload;
    },
    setBlogState(state, payload) {
      state.blogTitle = payload.blogTitle;
      state.blogTags = payload.blogTags;
      state.blogAuthor = payload.blogAuthor;
      state.blogCategory = payload.blogCategory;
      state.blogShortDescription = payload.blogShortDescription;
      state.blogPublished = payload.blogPublished;
      state.blogHTML = payload.blogHTML;
      state.blogPhotoFileURL = payload.blogCoverPhoto;
      state.blogPhotoName = payload.blogCoverPhotoName;
    },
    filterBlogPost(state, payload) {
      state.blogPosts = state.blogPosts.filter((post) => post.blogID !== payload);
      state.visibleBlogPosts = state.visibleBlogPosts.filter((post) => post.blogID !== payload);
    },
    updateUser(state, payload) {
      state.user = payload;
    },
    setProfileInfo(state, doc) {
      state.profileAdmin = doc.admin;
      state.profileId = firebase.auth().currentUser.uid;
      state.profileEmail = doc.email;
      state.profileFirstName = doc.firstName;
      state.profileLastName = doc.lastName;
      state.profileUsername = doc.username;
    },
    setProfileInitials(state) {
      state.profileInitials =
        state.profileFirstName.match(/(\b\S)?/g).join('') +
        state.profileLastName.match(/(\b\S)?/g).join('');
    },
    changeFirstName(state, payload) {
      state.profileFirstName = payload;
    },
    changeLastName(state, payload) {
      state.profileLastName = payload;
    },
    changeUsername(state, payload) {
      state.profileUsername = payload;
    },
  },
  actions: {
    async getCurrentUser({ commit }) {
      const dbResults = await db
        .collection('users')
        .doc(firebase.auth().currentUser.uid)
        .get()
        .then((doc) => {
          return doc.data();
        });
      commit('setProfileInfo', dbResults);
      commit('setProfileInitials');
    },
    async updateUserSettings({ commit, state }) {
      await db.collection('users').doc(firebase.auth().currentUser.uid).update({
        firstName: state.profileFirstName,
        lastName: state.profileLastName,
        username: state.profileUsername,
      });
      commit('setProfileInitials');
    },
    async getPost({ state }) {
      const dataBase = await db.collection('blogPosts').orderBy('date', 'desc');
      const dbResults = await dataBase.get();
      dbResults.forEach((doc) => {
        if (!state.blogPosts.some((post) => post.blogID === doc.id)) {
          const data = {
            blogID: doc.data().blogID,
            blogHTML: doc.data().blogHTML,
            blogCoverPhoto: doc.data().blogCoverPhoto,
            blogTitle: doc.data().blogTitle,
            blogTags: doc.data().blogTags,
            blogAuthor: doc.data().blogAuthor,
            blogCategory: doc.data().blogCategory,
            blogShortDescription: doc.data().blogShortDescription,
            blogPublished: doc.data().blogPublished,
            blogDate: doc.data().date,
            blogCoverPhotoName: doc.data().blogCoverPhotoName,
            author: doc.data().author,
          };
          state.blogPosts.push(data);
          if (data.blogPublished) {
            state.visibleBlogPosts.push(data);
          }
        }
      });
      const categories = await db
        .collection('extra')
        .doc('options')
        .get()
        .then((doc) => doc.data().categories);
      let categoriesCount = {};
      categories.forEach(category => {
        categoriesCount[category]= 0;
      })
      state.visibleBlogPosts.slice(1, 1000).forEach(blog => {
        categoriesCount[blog.blogCategory] += 1 
      })
      //state.blogCategories = categories
      state.blogCategories = categoriesCount;
      state.postLoaded = true;
    },
    async updatePost({ commit, dispatch }, payload) {
      commit('filterBlogPost', payload);
      await dispatch('getPost');
    },
    async deletePost({ commit }, payload) {
      const getPost = await db.collection('blogPosts').doc(payload);
      await getPost.delete();
      commit('filterBlogPost', payload);
    },
  },
  modules: {},
});
