<!-- eslint-disable prettier/prettier -->
<template>
  <div :class="`category-item ${active && 'active'}`" @click="setCategory(category)">
    <div class="name">{{category}}</div>
    <div v-if="true" class="number">{{ blogCategories[category] }}</div>
  </div>
</template>

<script>
export default {
  name: 'CategoryItem',
  props: ['category', 'setCategory', 'active'],
  methods: {
    //
  },
  computed: {
    blogCategories() {
      return this.$store.state.blogCategories;
    },
  },
};
</script>

<style lang="scss" scoped>
.category-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .name {
    color: #84858a;
    font-size: 1.1rem;
    width: 100%;
  }

  .number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    height: 25px;
    border-radius: 3px;
    color: white;
    font-size: 0.9rem;
    background-color: #46b8ff;
  }
}

.active {
  background-color: #46b8ff;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  .name {
    color: white;
  }
  .number {
    margin-left: 0.5rem;
    color: #84858a;
    background-color: white;
  }
}
</style>
