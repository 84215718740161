<!-- eslint-disable prettier/prettier -->
<template>
  <div>
    <SlideComponent title="¿Qué hacemos?" description="Creamos Vanglar, una sencilla pero poderosa herramienta especializada en capacitación y cumplimiento normativo (compliance). Contamos con un amplio portafolio de soluciones digitales: levantamiento de información en campo, soluciones de afiliación de socios y analítica de datos, entre otros." button="CONOCE MÁS" image="home/home2.png" :descriptionLeft=false height="1000">
    </SlideComponent>

    <SlideComponent title="App Vanglar" description="En su versión de aplicación móvil, es una innovadora herramienta de estudio, capacitación y comunicación; permite levantar información en tiempo real y dar seguimiento a procesos, casos de denuncia interna en empresas y realiza el análisis de toda esta información para la toma de decisiones." image="home/home3.png" :descriptionLeft=true height="1000">
    </SlideComponent>

    <SlideComponent subtitle="El desarrollo de soluciones más rápido del mercado" description="Vanglar utiliza su robusta plataforma tecnológica para automatizar y facilitar procesos internos de las organizaciones. Con base en ello desarrollamos:" image="home/home4.png" :descriptionLeft=false height="1000">
    <template v-slot:extra>
      <ul class="list">
        <li><span>levantamiento de estudios socioeconómicos</span></li>
        <li><span>generación de reportes automáticos</span></li>
        <li><span>información de daño a equipos</span></li>
        <li><span>registro de incidencias</span></li>
        <li><span>analítica automática de toda esta información, en tiempo récord y en la modalidad de software como servicio (SaaS)</span></li>
      </ul>
    </template>
    </SlideComponent>
  </div>
</template>

<script>
import SlideComponent from '@/components/general/SlideComponent.vue';

export default {
  name: 'HomeSlides',
  components: {
    SlideComponent,
  },
};
</script>
