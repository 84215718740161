<!-- eslint-disable prettier/prettier -->
<template>
  <b-container :style="cssProps" :class="this.descriptionLeft ? '' : 'reversed'" fluid v-observe-visibility="slideToTop">
    <div :class="this.descriptionLeft ? 'info' : 'info info-reversed'">
      <h1 class="header1">{{ this.title }}</h1>
      <p class="text-content">{{ this.description }}</p>
      <slot name="extra"></slot>
    </div>

    <div class="image">
      <b-img fluid :src="require('@/assets/' + this.image)" alt="content"></b-img>
    </div>

  </b-container>
</template>

<script>
export default {
  name: 'HeroComponent',
  props: ['title', 'description', 'image', 'descriptionLeft'],
  methods: {
    slideToTop(isVisible, entry) {
      if (isVisible) {
        // trigger animation for the element
        let domElement = entry.target;
        domElement.classList.add('slideToTop');
      }
    },
  },
  computed: {
    cssProps() {
      return {
        '--hero-height': 1000 + 'px',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
  overflow: hidden;
  max-width: 1920px;
  height: var(--hero-height);
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  opacity: 0;
}

.v-application .info {
  position: relative;
  z-index: 1;
  width: 30%;
  padding: 1rem 2rem;
  background-color: transparent !important;
  transform: translateX(20%);

  p {
    line-height: 4rem !important;
  }
}

.reversed {
  flex-direction: row-reverse;
}

.v-application .info-reversed {
  transform: translateX(-20%) !important;
}

.image {
  width: 70%;

  .img-fluid {
    transform: scale(1.2);
  }
}

///////////////////////////////////////
@media (max-width: 1750px) {
  .container-fluid {
    height: 900px;
  }

  .v-application .info {
    p {
      line-height: 3rem !important;
    }
  }
}

///////////////////////////////////////
@media (max-width: 1400px) {
  .container-fluid {
    height: 750px;
  }

  .v-application .info {
    width: 35%;
  }

  .image {
    width: 65%;
  }
}

///////////////////////////////////////
@media (max-width: 1100px) {
  .container-fluid {
    height: 650px;
  }

  .v-application .info {
    p {
      line-height: 2.5rem !important;
    }
  }
}

///////////////////////////////////////
@media (max-width: 900px) {
  .v-application .info {
    p {
      line-height: 2rem !important;
    }
  }
}

///////////////////////////////////////
@media (max-width: 767px) {
  .container-fluid {
    flex-direction: column;
    height: 750px;
    justify-content: start;
  }

  .v-application .info {
    transform: translateX(0);
    width: 80%;

    h1 {
      padding-top: 2rem;
    }
  }

  .image {
    width: 100%;

    .img-fluid {
      transform: scale(1);
    }
  }
}

///////////////////////////////////////
@media (max-width: 604px) {
  .container-fluid {
    height: 700px;
  }

  .v-application .info {
    width: 90%;
  }

  .image {
    width: 100%;

    .img-fluid {
      transform: scale(1);
    }
  }
}

///////////////////////////////////////
@media (max-width: 470px) {
  .container-fluid {
    height: 600px;
  }
}
</style>
