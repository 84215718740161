<!-- eslint-disable prettier/prettier -->
<template>
  <div class="card">
    <div class="icons" v-if="editPost">
      <div class="icon" @click="deletePost">
        <b-icon-trash-fill></b-icon-trash-fill>
      </div>
      <div class="icon" @click="editBlog">
        <b-icon-pencil-square></b-icon-pencil-square>
      </div>
    </div>
    <div class="image">
      <router-link :to="{ name: 'viewBlog', params: { blogid: this.post.blogID, title: post.blogTitle } }">
        <b-img :src="post.blogCoverPhoto" alt="content"></b-img>
      </router-link>
    </div>
    <div class="description">
      <!--<h6>{{ new Date(post.blogDate).toLocaleString("es-mx").split(',')[0] }}</h6>-->
      <router-link :to="{ name: 'viewBlog', params: { blogid: this.post.blogID, title: post.blogTitle } }">
        <span class="title">{{ post.blogTitle }}</span>
      </router-link>
      <span class="author">Por {{ post.blogAuthor }}</span>
      <span class="short-description" v-html="post.blogShortDescription"></span>
      <router-link :to="{ name: 'viewBlog', params: { blogid: this.post.blogID, title: post.blogTitle } }">
        <span class="more">Leer más</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlogCard',
  props: ['post'],
  methods: {
    editBlog() {
      this.$router.push({
        name: 'editBlog',
        params: { blogid: this.post.blogID },
      });
    },
    deletePost() {
      this.$store.dispatch('deletePost', this.post.blogID);
    },
  },
  computed: {
    editPost() {
      return this.$store.state.editPost;
    },
  },
  beforeDestroy() {
    this.$store.commit('toggleEditPost', false);
  },
};
</script>

<style lang="scss" scoped>
.card {
  width: 80%;
  display: flex;
  position: relative;
  flex-direction: row;
  gap: 1rem;
  border: none;

  //border-radius: 10px;
  //box-shadow: 0 0 20px rgba(0, 0, 0, 0.17);
  .icons {
    position: absolute;
    display: flex;
    gap: 2rem;
    top: 10px;
    right: 10px;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      border-radius: 50%;
      background-color: #303030;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.17);
      cursor: pointer;
      transition: 0.5s ease all;

      .b-icon {
        color: rgb(255, 255, 255);
      }
    }

    .icon:hover {
      background-color: white;

      .b-icon {
        color: #303030;
      }
    }
  }

  .image {
    align-self: center;
    width: 40%;
    height: 250px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .description {
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 60%;

    .author {
      font-size: 0.9rem;
      color: #b5b5b5;
    }

    .title {
      color: #272727;
      font-size: 1.7rem !important;
      font-weight: 600;
    }

    .short-description,
    .more {
      color: #8d8e94;
    }

    .short-description {
      //max-height: 100px;
      //overflow: hidden;
      //text-overflow: ellipsis;
    }
  }
}

@media (max-width: 1200px) {
  .card {
    width: 80%;

    .description {
      width: 50%;
    }

    .image {
      width: 50%;
    }
  }
}

@media (max-width: 1000px) {
  .card {
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
    width: 80%;

    .description {
      width: 90%;
      gap: 0.5rem;
    }

    .image {
      border-radius: 20px;
      width: 90%;
    }
  }
}

@media (max-width: 570px) {
  .card {
    width: 100%;

    .description {
      width: 100%;

      .short-description {
        width: 100%;
        //max-height: 145px;
      }
    }

    .image {
      width: 100%;

      img {
        //transform: scale(1.3);
      }
    }
  }
}

@media (max-width: 1100px) {
  //
}

@media (max-width: 1100px) {
  //
}
</style>
