<!-- eslint-disable prettier/prettier -->
<template>
  <div>
    <HeroComponent title="Seguridad y Cumplimiento" description="Moderniza y simplifica tu sistema de denuncias y alertas." image="compliance/compliance1.png" :descriptionLeft=true>
    <template v-slot:extra>
    </template>
    </HeroComponent>
    <SlideComponent subtitle="Aseguramiento normativo" description="Vanglar ofrece soluciones especializadas que facilitan y automatizan tareas relacionadas con KYC, con el seguimiento a denuncias y quejas, y con la elaboración de reportes, procesos de capacitación y de comunicación" image="compliance/compliance2.png" button="CONOCE MÁS" :descriptionLeft=false height="800">
    </SlideComponent>
    <SlideComponent subtitle="Conoce a tu cliente" description="Con soluciones personalizadas, sencillas y en tiempo real, verifica la identidad de los usuarios, reduce el fraude y simplifica el cumplimiento de las normas KYC (Know Your Client) y AML (Anti Money Laundry)." image="compliance/compliance3.png" :descriptionLeft=true height="800">
    </SlideComponent>
    <SlideComponent subtitle="Quejas y denuncias" description="La herramienta de seguimiento a denuncias articula un proceso estándar y transparente que contribuye a un ambiente de trabajo sano.  En estos momentos difíciles, deja que Vanglar automatice todo lo posible para enfocarte en lo importante: escuchar al equipo de trabajo." image="compliance/compliance4.png" :descriptionLeft=false height="800">
    </SlideComponent>
    <SlideComponent subtitle="Capacitación " description="Vanglar tiene amplia experiencia en tópicos de ética y anticorrupción, protección de datos personales, prevención de lavado de dinero, ciberseguridad, Norma 035 (México) y normas ambientales, entre otras." image="compliance/compliance5.png" :descriptionLeft=true height="800">
    </SlideComponent>
    <ComplianceCards></ComplianceCards>
    <v-col align="center" class="mb-15">
      <router-link :to="{name : 'contacto'}">
        <b-button size="lg" variant="primary">CONTÁCTANOS</b-button>
      </router-link>
    </v-col>
  </div>
</template>

<script>
import HeroComponent from '@/components/general/HeroComponent.vue';
import SlideComponent from '@/components/general/SlideComponent.vue';
import ComplianceCards from '@/components/compliance/ComplianceCards.vue';

export default {
  /*
  metaInfo() {
    return {
      title: 'Compliance',
      meta: [
        {
          name: 'description',
          content:
            'Vanglar es una aplicación para estudiar diariamente diversos temas que te interesen para prepararte para una evaluación, un examen o una certificación.',
        },
        {
          property: 'og:title',
          content: 'Compliance',
        },
        { property: 'og:site_name', content: 'Vanglar' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    };
  },
  */
  components: {
    HeroComponent,
    SlideComponent,
    ComplianceCards,
  },
};
</script>

<style lang="scss" scoped>
//
</style>
