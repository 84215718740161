<!-- eslint-disable prettier/prettier -->
<template>
  <b-container fluid>
    <div class="brand">
      <b-img class="logo" :src="require('@/assets/vanglar.png')" alt="logo vanglar"></b-img>
      <div class="line"></div>
    </div>
    <div class="contact">
      <p><b-icon-envelope></b-icon-envelope>victor.quiroz@vanglar.com</p>
      <p><b-icon-geo-alt></b-icon-geo-alt>Av. Patriotismo 767, piso 6, San Juan, 03730, CDMX</p>
      <p><b-icon-telephone></b-icon-telephone>+52 55 1370 7787</p>
      <div class="links">
        <a target="_blank" href="https://www.facebook.com/vanglarmx"><b-icon-facebook></b-icon-facebook></a>
        <a target="_blank" href="https://twitter.com/VanglarMx"><b-icon-twitter></b-icon-twitter></a>
        <a target="_blank" href="https://mx.linkedin.com/company/vanglar"><b-icon-linkedin></b-icon-linkedin></a>
      </div>
    </div>
    <div class="buttons">
      <a target="_blank" class="appstore" href="https://apps.apple.com/us/app/vanglar-wp/id1570157235"></a>
      <a target="_blank" class="playstore" href="https://play.google.com/store/apps/details?id=com.vanglar.vanglar_flutter&pli=1"></a>
      <p>&copy; 2023 por Vanglar.</p>
    </div>
  </b-container>
</template>

<script>
export default {
  name: 'FooterComponent',
};
</script>

<style lang="scss" scoped>
.container-fluid {
  background-color: #0b2653;
  max-width: 1920px;
  display: flex;
  height: 400px;
  .brand {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo {
      width: 60%;
    }
    .line {
      margin-left: 2rem;
      width: 2px;
      height: 80%;
      background-color: rgb(255, 255, 255);
    }
  }
  .contact {
    width: 60%;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    p {
      text-align: start !important;
      font-size: 2rem !important;
      font-weight: 800;
      color: #bbbec7;
    }

    .b-icon.bi {
      color: #43baff;
      margin: 0 1rem;
    }
    .links {
      display: flex;
      gap: 3rem;
      font-size: 2rem !important;
    }
  }
  .buttons {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    p {
      font-size: 1.7rem !important;
      color: white;
    }

    .appstore {
      //padding: 2.11rem 6.3rem;
      width: 211px;
      height: 70px;
      background-image: url('@/assets/appstore.svg');
      background-size: cover;
    }
    .playstore {
      //padding: 2.11rem 7.2rem;
      width: 211px;
      height: 63px;
      background-image: url('@/assets/playstore.png');
      background-size: cover;
    }
  }
}

///////////////////////////////////////
@media (max-width: 1750px) {
  .container-fluid {
    height: 300px;
    .contact {
      p {
        font-size: 1.5rem !important;
      }
    }
    .buttons {
      p {
        font-size: 1.4rem !important;
      }
      .appstore {
        width: 211px;
        height: 70px;
      }
      .playstore {
        //padding: 2.11rem 7.2rem;
        width: 211px;
        height: 63px;
      }
    }
  }
}

///////////////////////////////////////
@media (max-width: 1200px) {
  .container-fluid {
    height: 250px;
    .contact {
      p {
        font-size: 1.2rem !important;
      }
    }
    .buttons {
      p {
        font-size: 1.1rem !important;
      }
      .appstore {
        width: 148px;
        height: 49px;
      }
      .playstore {
        //padding: 2.11rem 7.2rem;
        width: 148px;
        height: 44px;
      }
    }
  }
}

///////////////////////////////////////
@media (max-width: 800px) {
  .container-fluid {
    height: auto;
    flex-direction: column;
    gap: 3rem;
    .brand {
      padding-top: 2rem;
      width: 100%;
      .logo {
        width: 20%;
      }
      .line {
        display: none;
      }
    }
    .contact {
      width: 100%;
      padding: 0;
      //
      p {
        font-size: 1.2rem !important;
      }
    }
    .contact .links {
      align-self: center;
    }
    .buttons {
      width: 100%;
      .appstore {
        width: 148px;
        height: 49px;
      }
      .playstore {
        //padding: 2.11rem 7.2rem;
        width: 148px;
        height: 44px;
      }
    }
  }
}
</style>
