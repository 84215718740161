<!-- eslint-disable prettier/prettier -->
<template>
    <b-container fluid>
      <div class="title">
        <h2 class="header2">Equipo Directivo</h2>
      </div>
      <div class="cards">
        <div class="card-el">
          <v-container style="height: 10%;"></v-container>
          <h3 class="header3">Víctor Quiroz</h3>
          <h5 class="font-italic" style="color:gray">Director General</h5>
          <p class="text-content text-center my-8">
            Ingeniero Industrial y de Sistemas por el ITESM, con especialidades en Ingeniería Financiera e Ingeniería de Calidad. Maestro en Políticas Públicas por la Universidad de Harvard. Es director general del Laboratorio de Crecimiento.
          </p>
          <p class="text-content">
            victor.quiroz@vanglar.com
          </p>
        </div>
        <div class="card-el">
          <v-container style="height: 10%;"></v-container>
          <h3 class="header3">Israel Toledo</h3>
          <h5 class="font-italic" style="color:gray">Director de Desarrollo Tecnológico</h5>
          <p class="text-content text-center my-8">
            Especialista en Data Mining, Machine Learning y desarrollo de sistemas en Android, Java, C/C ++, JavaScript. Es candidato a Doctor en Ciencias de la Computación por el Instituto Politécnico Nacional. 
          </p>
          <p class="text-content">
            israel.tolero@vanglar.com 
          </p>
        </div>
        <div class="card-el">
          <v-container style="height: 5%;"></v-container>
          <h3 class="header3">Augusto Miguel Gallego</h3>
          <h5 class="font-italic" style="color:gray">Director Comercial</h5>
          <p class="text-content text-center my-8">
            Licenciado en Administración de Empresas por el ITESM. Colaboró con Chrysler en áreas comerciales y con Canal 13 como Director de Promoción. Fue Director de Club Vacacional de Banamex y ha sido director comercial en empresas de infraestructura.
          </p>
        </div>
    </div>
    </b-container>
  </template>

<script>
export default {
  name: 'AnaliticaDatosCards',
};
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  flex-direction: column;
  margin: 0rem auto 0 auto;
  width: 70%;
}

.container-fluid {
  max-width: 1920px;
  background: rgb(255, 255, 255);
  padding-top: 7rem;
}
.cards {
  margin-top: 4.5rem;
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-grow: 1;
  text-align: center;
  gap: 3rem;
  .card-el {
    width: 27%;
    padding: 1rem 1.7rem;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.17);
    .icon {
      width: 110px;
      //filter: invert(44%) sepia(81%) saturate(2532%) hue-rotate(210deg)
      //  brightness(99%) contrast(92%);
      margin: 1.7rem 0;
    }
    p {
      font-size: 1.7rem !important;
    }
  }
}

///////////////////////////////////////
@media (max-width: 1750px) {
  .card-el {
    p {
      font-size: 1.5rem !important;
    }
  }
}

///////////////////////////////////////
@media (max-width: 1400px) {
  .cards {
    margin-top: 3rem;
    text-align: center;
    gap: 3rem;
    .card-el {
      width: 40%;
      .icon {
        width: 80px;
      }
    }
  }
}

///////////////////////////////////////
@media (max-width: 1100px) {
  .cards {
    gap: 1.5rem;
    .card-el {
      p {
        font-size: 1.3rem !important ;
      }
    }
  }
}

///////////////////////////////////////
@media (max-width: 900px) {
  .cards {
    gap: 2.5rem;
    .card-el {
      width: 45%;
    }
  }
}

///////////////////////////////////////
@media (max-width: 767px) {
  .cards {
    .card-el {
      width: 80%;
      p {
        font-size: 1.2rem !important;
      }
    }
  }
}

///////////////////////////////////////
@media (max-width: 604px) {
  .title {
    width: 100%;
    //AGREGAR ALGO
  }

  .cards {
    gap: 2rem;
    .card-el {
      width: 90%;
    }
  }
}

///////////////////////////////////////
@media (max-width: 470px) {
  .cards {
    .card-el {
      width: 90%;
      p {
        font-size: 1.1rem !important;
      }
    }
  }
}
</style>
