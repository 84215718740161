<!-- eslint-disable prettier/prettier -->
<template>
  <div class="form-container">
    <form class="signup">
      <h2>Crear cuenta</h2>
      <div class="inputs">
        <input type="text" v-model="username" placeholder="Username" />
        <input type="text" v-model="firstName" placeholder="Nombre" />
        <input type="text" v-model="lastName" placeholder="Apellido" />
        <input type="text" v-model="email" placeholder="Email" />
        <input type="password" v-model="password" placeholder="Password" />
      </div>
      <b-button @click.prevent="signUp" variant="primary">
        Registrarse
      </b-button>
      <span>¿Ya tienes cuenta? <router-link to="/login">Inicia sesión</router-link>.</span>
    </form>
    <div class="background">
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import db from '../assets/firebase/firebaseInit';
export default {
  name: 'signUp',
  data() {
    return {
      username: '',
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    };
  },
  methods: {
    async signUp() {
      if (
        this.username === '' ||
        this.firstName === '' ||
        this.lastName === '' ||
        this.email === '' ||
        this.password === ''
      ) {
        console.log('Campos incompletos');
        return;
      } else {
        const firebaseAuth = await firebase.auth();
        const createUser = await firebaseAuth.createUserWithEmailAndPassword(
          this.email,
          this.password
        );
        const result = await createUser;
        const dataBase = db.collection('users').doc(result.user.uid);
        console.log(dataBase);
        await dataBase.set({
          username: this.username,
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          admin: false,
        });
        this.$router.push({ name: 'home' });
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-container {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  .signup {
    width: 30%;
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem;
    gap: 1rem;
    h2 {
      text-align: start !important;
    }
    .inputs {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      input {
        border: none;
        border-radius: 5px;
        padding: 0.5rem 1rem;
        background-color: #f8f6f6;
      }
    }
  }
  .background {
    width: 70%;
    height: 100%;
    background-color: #1e1f72;
  }
}
</style>
