<template>
  <div class="modal" ref="modal">
    <div class="modal-content">
      <button @click="closePreview">Close</button>
      <b-img fluid :src="this.blogCoverPhoto" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    closePreview() {
      this.$store.commit('openPhotoPreview');
    },
  },
  computed: {
    blogCoverPhoto() {
      return this.$store.state.blogPhotoFileURL;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  .modal-content {
    display: flex;
    justify-content: center;
    position: relative;
    border-radius: 12px;
    background-color: white;
    height: 400px;
    width: 600px;
    padding: 50px;
    img {
      margin-top: 16px;
      display: block;
      width: auto;
      height: 100%;
    }
  }
}
</style>
