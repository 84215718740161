<template>
  <div class="post-view" v-if="currentBlog">
    <div class="container quillWrapper">
      <h2 class="header2">{{ this.currentBlog[0].blogTitle }}</h2>
      <b-img
        class="cover-photo"
        fluid
        :src="this.currentBlog[0].blogCoverPhoto"
        alt=""
      />
      <p class="author">Por {{ this.currentBlog[0].blogAuthor }}</p>
      <h4 class="date">
        <!-- {{ new Date(this.currentBlog[0].blogDate).toLocaleDateString('es-mx') }}-->
        {{ new Date(this.currentBlog[0].blogDate).getDate() }} de
        {{ this.months[new Date(this.currentBlog[0].blogDate).getMonth()] }},
        {{ new Date(this.currentBlog[0].blogDate).getFullYear() }}
      </h4>
      <div
        class="post-content ql-editor"
        v-html="this.currentBlog[0].blogHTML"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  /*
  metaInfo() {
    return {
      title: `${this.blogTitle} - Blogs`,
      meta: [
        {
          name: 'description',
          content:
            'Vanglar es una aplicación para estudiar diariamente diversos temas que te interesen para prepararte para una evaluación, un examen o una certificación.',
        },
        {
          property: 'og:title',
          content: 'Blogs',
        },
        { property: 'og:site_name', content: 'Vanglar' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    };
  },
  */
  data() {
    return {
      currentBlog: null,
      months: [
        'enero',
        'febrero',
        'marzo',
        'abril',
        'mayo',
        'junio',
        'julio',
        'agosto',
        'septiembre',
        'octubre',
        'noviembre',
        'diciembre',
      ],
    };
  },
  async mounted() {
    this.currentBlog = await this.$store.state.blogPosts.filter((post) => {
      return post.blogID === this.$route.params.blogid;
    });
  },
  computed: {
    blogTitle() {
      const title = this.$store.state.blogTitle;
      return title;
    },
  },
};
</script>

<style lang="scss" scoped>
.post-view {
  .date {
    color: #acacac;
    font-size: 14px;
    margin-bottom: 24px;
  }
}
</style>
