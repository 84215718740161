<!-- eslint-disable prettier/prettier -->
<template>
  <div class="admin">
    <div class="container">
      <h2>Administración</h2>
      <div class="admin-info">
        <h3>Agregar administrador</h3>
        <div class="input">
          <input placeholder="Ingresa el correo que quieres agregar como administrador" type="text" id="addAdmins" v-model="adminEmail" />
        </div>
        <span>{{ this.functionMsg }}</span>
        <b-button size="lg" variant="primary">Aceptar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: 'Admin',
      meta: [
        {
          name: 'description',
          content:
            'Vanglar es una aplicación para estudiar diariamente diversos temas que te interesen para prepararte para una evaluación, un examen o una certificación.',
        },
        {
          property: 'og:title',
          content: 'Admin',
        },
        { property: 'og:site_name', content: 'Vanglar' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    };
  },
  data() {
    return {
      adminEmail: '',
      functionMsg: null,
    };
  },
  methods: {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.admin {
  .container {
    max-width: 1000px;
    padding: 60px 25px;
  }
  .admin-info {
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.17);
    padding: 32px;
    background-color: #f1f1f1;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: 32px auto;
  }
  span {
    font-size: 14px;
  }
  .input {
    margin: 16px 0;
    .label {
      font-size: 14px;
      display: block;
      padding-bottom: 6px;
    }
    input {
      width: 100%;
      border: none;
      background-color: #ffffff;
      padding: 8px;
      height: 50px;
      @media (min-width: 900px) {
        &:focus {
          outline: none;
        }
      }
    }
  }
}
</style>
