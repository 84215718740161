<template>
  <div class="post-view" v-if="admin">
    <div class="container quillWrapper">
      <a @click="$router.go(-1)" v-if="true" class="router-button"> Volver </a>
      <h2 class="header2">{{ this.blogTitle }}</h2>
      <b-img class="cover-photo" fluid :src="blogCoverPhoto" alt="" />
      <p class="author">Por {{ this.blogAuthor }}</p>
      <div class="post-content ql-editor" v-html="blogHTML"></div>
    </div>
  </div>
</template>

<script>
export default {
  /*
  metaInfo() {
    return {
      title: 'Blog Preview',
      meta: [
        {
          name: 'description',
          content:
            'Vanglar es una aplicación para estudiar diariamente diversos temas que te interesen para prepararte para una evaluación, un examen o una certificación.',
        },
        {
          property: 'og:title',
          content: 'Blog Preview',
        },
        { property: 'og:site_name', content: 'Vanglar' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    };
  },
  */
  computed: {
    admin() {
      return this.$store.state.profileAdmin;
    },
    blogTitle() {
      return this.$store.state.blogTitle;
    },
    blogHTML() {
      return this.$store.state.blogHTML;
    },
    blogCoverPhoto() {
      return this.$store.state.blogPhotoFileURL;
    },
    blogAuthor() {
      return this.$store.state.blogAuthor;
    },
  },
};
</script>

<style lang="scss">
.post-view {
  min-height: 100%;
  .container {
    max-width: 1000px;
    padding: 60px 25px;
  }
  .router-button {
    text-decoration: none;
    color: white;
    transition: 0.5s ease-in-out all;
    align-self: center;
    font-size: 14px;
    cursor: pointer;
    border-radius: 20px;
    padding: 12px 24px;
    color: white;
    background-color: #303030;
    text-decoration: none;
    &:hover {
      background-color: rgba(48, 48, 48, 0.7);
    }
  }
  .author {
    color: #b5b5b5;
  }
  .ql-editor {
    padding: 0;
  }
  img {
    //width: 100%;
    margin-bottom: 32px;
  }
  .cover-photo {
    width: 100%;
  }
}
</style>
