<!-- eslint-disable prettier/prettier -->
<template>
  <b-container fluid>
    <h1 class="header1">Operación sin fronteras en siete países</h1>
    <b-row class="logos-container" align-h="around">
      <b-img class="logo animation-delay-1" v-observe-visibility="fadeIn"
        :src="require('@/assets/home/logos/logo-hospital.jpg')" alt="logo hospital"></b-img>
      <b-img class="logo animation-delay-2" v-observe-visibility="fadeIn"
        :src="require('@/assets/home/logos/logo-cide.jpg')" alt="logo cide"></b-img>
      <b-img class="logo animation-delay-3" v-observe-visibility="fadeIn"
        :src="require('@/assets/home/logos/logo-conacyt.jpg')" alt="logo conacyt"></b-img>
      <b-img class="logo animation-delay-4" v-observe-visibility="fadeIn"
        :src="require('@/assets/home/logos/logo-unam.jpg')" alt="logo unam"></b-img>
      <b-img class="logo animation-delay-5" v-observe-visibility="fadeIn"
        :src="require('@/assets/home/logos/logo-ford.jpg')" alt="logo ford"></b-img>
      <b-img class="logo animation-delay-1" v-observe-visibility="fadeIn"
        :src="require('@/assets/home/logos/logo-aleatica.jpg')" alt="logo aleatica"></b-img>
      <b-img class="logo animation-delay-2" v-observe-visibility="fadeIn"
        :src="require('@/assets/home/logos/logo-tresalia.jpg')" alt="logo tresalia"></b-img>
      <b-img class="logo animation-delay-3" v-observe-visibility="fadeIn"
        :src="require('@/assets/home/logos/logo-abilia.jpg')" alt="logo abilia"></b-img>
      <b-img class="logo animation-delay-4" v-observe-visibility="fadeIn"
        :src="require('@/assets/home/logos/logo-hersheys.jpg')" alt="logo hersheys"></b-img>
      <b-img class="logo animation-delay-5" v-observe-visibility="fadeIn"
        :src="require('@/assets/home/logos/logo-sec-economia.jpg')" alt="logo economia"></b-img>
      <b-img class="logo animation-delay-1" v-observe-visibility="fadeIn"
        :src="require('@/assets/home/logos/logo-tvh.jpg')" alt="Image 1"></b-img>
      <b-img class="logo animation-delay-2" v-observe-visibility="fadeIn"
        :src="require('@/assets/home/logos/logo-club-asistencia.jpg')" alt="logo club asistencia"></b-img>
      <b-img class="logo animation-delay-3" v-observe-visibility="fadeIn"
        :src="require('@/assets/home/logos/logo-finanmadrid.jpg')" alt="logo finanmadrid"></b-img>
      <b-img class="logo animation-delay-4" v-observe-visibility="fadeIn"
        :src="require('@/assets/home/logos/logo-medistik.jpg')" alt="logo medistik"></b-img>
      <b-img class="logo animation-delay-5" v-observe-visibility="fadeIn"
        :src="require('@/assets/home/logos/logo-bordernomics.jpg')" alt="logo bordernomics"></b-img>
      <b-img class="logo animation-delay-1" v-observe-visibility="fadeIn"
        :src="require('@/assets/home/logos/logo-g2m.jpg')" alt="logo g2m"></b-img>
      <b-img class="logo animation-delay-2" v-observe-visibility="fadeIn"
        :src="require('@/assets/home/logos/logo-cibanco.jpg')" alt="logo cibanco"></b-img>
      <b-img class="logo animation-delay-3" v-observe-visibility="fadeIn"
        :src="require('@/assets/home/logos/logo-c3.jpg')" alt="logo c3"></b-img>
      <b-img class="logo animation-delay-4" v-observe-visibility="fadeIn"
        :src="require('@/assets/home/logos/logo-sec-medio-amb.jpg')" alt="logo medio ambiente"></b-img>
      <b-img class="logo animation-delay-5" v-observe-visibility="fadeIn"
        :src="require('@/assets/home/logos/logo-matt.jpg')" alt="logo matt"></b-img>
      <b-img class="logo animation-delay-1" v-observe-visibility="fadeIn"
        :src="require('@/assets/home/logos/logo-forte.jpg')" alt="logo forte"></b-img>
      <b-img class="logo animation-delay-2" v-observe-visibility="fadeIn"
        :src="require('@/assets/home/logos/logo-energeticos.jpg')" alt="energeticos"></b-img>
      <b-img class="logo animation-delay-3" v-observe-visibility="fadeIn"
        :src="require('@/assets/home/logos/logo-preve.jpg')" alt="logo preve"></b-img>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'HomeLogos',
  methods: {
    fadeIn(isVisible, entry) {
      if (isVisible) {
        let domElement = entry.target;
        domElement.classList.add('fadeIn');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
  max-width: 1920px;
  padding: 12rem 1rem;
  background-color: #2e5894;
}

.logos-container {
  padding: 1rem 14.5rem;
}

.header1 {
  color: white !important;
}

.logo {
  opacity: 0;
  width: 17%;
  margin: 1.5% 0.8%;
}

@media (max-width: 1750px) {
  .container-fluid {
    padding: 10rem 1rem;
  }

  .header1 {
    font-size: 4.3rem;
  }

  .logos-container {
    padding: 1rem 12rem;
  }
}

@media (max-width: 1400px) {
  .container-fluid {
    padding: 8rem 1rem;
  }

  .header1 {
    font-size: 4rem;
  }

  .logos-container {
    padding: 1rem 9rem;
  }
}

@media (max-width: 1100px) {
  .container-fluid {
    padding: 6rem 1rem;
  }

  .header1 {
    font-size: 3.5rem;
  }

  .logos-container {
    padding: 1rem 6rem;
  }
}

@media (max-width: 900px) {
  .container-fluid {
    padding: 4rem 1rem;
  }

  .header1 {
    font-size: 3rem;
  }

  .logos-container {
    padding: 1rem 4rem;
  }
}

@media (max-width: 767px) {
  .header1 {
    font-size: 2.5rem;
  }

  .logos-container {
    padding: 1rem 2.5rem;
  }
}

@media (max-width: 604px) {
  .container-fluid {
    padding: 3rem 1rem;
  }

  .header1 {
    font-size: 2.2rem;
  }

  .logos-container {
    padding: 1rem 2rem;
  }
}

@media (max-width: 470px) {
  .container-fluid {
    padding: 2.5rem 1rem;
  }

  .header1 {
    font-size: 1.8rem;
  }

  .logos-container {
    padding: 1rem 1rem;
  }
}

@media (max-width: 377px) {
  .container-fluid {
    padding: 2rem 1rem;
  }

  .header1 {
    font-size: 1.5rem;
  }

  .logos-container {
    padding: 1rem 0.5rem;
  }
}
</style>
