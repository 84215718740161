<!-- eslint-disable prettier/prettier -->
<template>
  <div>
    <HomeHero></HomeHero>
    <HomeSlides></HomeSlides>
    <HomeLogos></HomeLogos>
    <HomeCards></HomeCards>
    <HomeContact></HomeContact>
  </div>
</template>

<script>
import HomeHero from '@/components/home/HomeHero.vue';
import HomeSlides from '@/components/home/HomeSlides.vue';
import HomeLogos from '@/components/home/HomeLogos.vue';
import HomeCards from '@/components/home/HomeCards.vue';
import HomeContact from '@/components/home/HomeContact.vue';

/*
import { useHead } from '@vueuse/head';

useHead({
  title: 'Vanglar Homepage',
  meta: [
    {
      name: 'description',
      content: 'Homepage description',
    },
    {
      name: 'og:title',
      content: 'Vanglar test',
    },
  ],
});
*/

export default {
  /*
  metaInfo() {
    return {
      title: 'Homepage',
      meta: [
        {
          name: 'description',
          content:
            'Vanglar es una aplicación para estudiar diariamente diversos temas que te interesen para prepararte para una evaluación, un examen o una certificación.',
        },
        {
          property: 'og:title',
          content: 'Homepage title tag og test',
        },
        { property: 'og:site_name', content: 'Vanglar site name test' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    };
  },
  */
  components: {
    HomeHero,
    HomeSlides,
    HomeLogos,
    HomeCards,
    HomeContact,
  },
};
</script>
