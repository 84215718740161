<!-- eslint-disable prettier/prettier -->

<template>
    <div>
      <div class="title" v-observe-visibility="slideToTop">
       <h1 class="header1">Nosotros</h1>
       <h2 class="header2 text-center">¿Qué es Vanglar?</h2>
       <p class="text-content">Es una empresa mexicana pionera en el desarrollo de soluciones tecnológicas para empresas enfocadas en la generación de valor y en el aseguramiento de sus procesos de capacitación, cumplimiento, denuncia, levantamiento de información y toma de decisiones.</p>
       <p class="text-content">Ofrece soluciones a la medida, confiables y eficientes, que ayudan a acortar sensiblemente tiempos de instrumentación y a resolver problemas complejos con base en el análisis de la información.</p>
      </div>
      
      <v-container class="fluid mt-10 mb-15" v-observe-visibility="slideToTop">
        <v-row class="justify-center">
          <v-col align="center">
            <b-img fluid :src="require('@/assets/nosotros/nosotros01.webp')" alt="content"></b-img>
          </v-col>
        </v-row>
      </v-container>

      <SlideComponent subtitle="Misión" description="Contribuimos a maximizar los recursos humanos de nuestros clientes con herramientas prácticas, sencillas y útiles, que permiten recuperar tiempo para tareas que aporten valor a la empresa y que mejoren la vida de las personas." image="nosotros/nosotros02.webp" :descriptionLeft=false>
        <template v-slot:extra>
            <v-container style="height: 100px;"></v-container>
            <h2>Valores</h2>
            <ul class="list">
                <li>
                    <span>Personas, personas y personas</span>
                </li>
                <li>
                    <span>Tecnología al servicio de la humanidad</span>
                </li>
                <li>
                    <span>Transparencia</span>
                </li>
                <li>
                    <span>Profesionalismo</span>
                </li>
                <li>
                    <span>Creación de valor</span>
                </li>
                <li>
                    <span>Soluciones a la medida</span>
                </li>
            </ul>
        </template>
      </SlideComponent>
      <div class="title">
       <h2 class="header2 my-15">Historia</h2>
       <v-row>
        <v-col class="px-10" cols="12" md="6">
            <p class="text-content text-left">Vanglar se fundó en el 2017 mediante la integración de dos empresas: Laboratorio de Crecimiento, especializada en el análisis de datos, y otra iniciativa enfocada en soluciones de educación digital.</p>
            <p class="text-content text-left">Desde entonces ha brindado servicio a más de 10 mil usuarios en siete países.</p>
        </v-col>
        <v-col class="pl-10" cols="12" md="6">
            <p class="text-content text-left">Cuenta con un equipo experimentado y diverso que permite la creación de soluciones poderosas en áreas como:</p>
            <ul class="list">
                <li>
                    <span>Análisis y minería de datos</span>
                </li>
                <li>
                    <span>Aseguramiento de procesos</span>
                </li>
                <li>
                    <span>Marketing</span>
                </li>
                <li>
                    <span>Comunicación estratégica</span>
                </li>
                <li>
                    <span>Creación de contenidos</span>
                </li>
            </ul>
        </v-col>
       </v-row>
      </div>
      <div>
        <v-row>
          <v-col>
            <b-img :src="require('@/assets/nosotros/nosotros03.webp')" alt="content" fluid></b-img>
          </v-col>
        </v-row>
      </div>
      <NosotrosCards></NosotrosCards>
    </div>
  </template>

<script>
import SlideComponent from '@/components/general/SlideComponent.vue';
import NosotrosCards from '@/components/nosotros/NosotrosCards.vue';

export default {
  /*
  metaInfo() {
    return {
      title: 'Compliance',
      meta: [
        {
          name: 'description',
          content:
            'Vanglar es una aplicación para estudiar diariamente diversos temas que te interesen para prepararte para una evaluación, un examen o una certificación.',
        },
        {
          property: 'og:title',
          content: 'Compliance',
        },
        { property: 'og:site_name', content: 'Vanglar' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    };
  },
  */
  components: {
    SlideComponent,
    NosotrosCards,
  },
  methods: {
    slideToTop(isVisible, entry) {
      if (isVisible) {
        // trigger animation for the element
        let domElement = entry.target;
        domElement.classList.add('slideToTop');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  flex-direction: column;
  margin: 5rem auto 0 auto;
  width: 80%;
}

@media (max-width: 767px) {
  .title {
    width: 90%;
  }
}

@media (max-width: 470px) {
  .title {
    margin-top: 2rem;
  }
}
</style>
