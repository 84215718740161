<!-- eslint-disable prettier/prettier -->
<template>
  <b-container :style="cssProps" :class="this.descriptionLeft ? 'content' : 'content reversed'" fluid>
    <div class="info" :class="this.descriptionLeft ? (this.isTextVisible ? 'slideToRight' : 'slideToRightOut') : (this.isTextVisible ? 'slideToLeft' : 'slideToLeftOut')">
      <div class="text-trigger-in" v-observe-visibility="slideText"></div>
      <h1 class="header1" v-if="this.title">
        {{ this.title }}
      </h1>
      <h2 class="header2" v-if="this.subtitle">
        {{ this.subtitle }}
      </h2>
      <p class="text-content" v-if="this.description">
        {{ this.description }}
      </p>
      <slot name="extra"></slot>
      <router-link v-if="this.button" :to="{ name: 'contacto' }">
        <b-button size="lg" variant="primary">
          {{ this.button }}
        </b-button>
      </router-link>

    </div>
    <div class="image" :class="!this.descriptionLeft ? (this.isImageVisible ? 'slideToRight' : 'slideToRightOut') : (this.isImageVisible ? 'slideToLeft' : 'slideToLeftOut')">
      <div class="image-trigger-in" v-observe-visibility="slideImage"></div>
      <b-img fluid :src="require('@/assets/' + this.image)" alt="content"></b-img>
    </div>
  </b-container>
</template>

<script>
export default {
  name: 'SlideComponent',
  data() {
    return {
      isTextVisible: false,
      isImageVisible: false,
    };
  },
  props: [
    'title',
    'subtitle',
    'description',
    'button',
    'image',
    'descriptionLeft',
    'height',
  ],
  methods: {
    slideText(isVisible) {
      this.isTextVisible = isVisible;
    },
    slideImage(isVisible) {
      this.isImageVisible = isVisible;
    },
  },
  computed: {
    cssProps() {
      return {
        '--slide-height-1920': this.height + 'px',
        '--slide-height-1750': this.height * 0.9 + 'px',
        '--slide-height-1400': this.height * 0.75 + 'px',
        '--slide-height-1100': this.height * 0.65 + 'px',
        '--slide-height-900': this.height * 0.65 + 'px',
        '--slide-height-767': this.height * 0.85 + 'px',
        '--slide-height-604': this.height * 0.85 + 'px',
        '--slide-height-470': this.height * 0.7 + 'px',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
  overflow: hidden;
  max-width: 1920px;
  height: var(--slide-height-1920);
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.reversed {
  flex-direction: row-reverse;
}

.v-application .info {
  width: 50%;
  padding: 1rem 4rem;
  background-color: transparent !important;
  position: relative;
  opacity: 0;
  .text-trigger-in {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 50px;
    pointer-events: none;
    transform: translate(-50%, -50%);
  }
}

.image {
  width: 50%;
  position: relative;
  opacity: 0;

  .image-trigger-in {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 50px;
    pointer-events: none;
    transform: translate(-50%, -50%);
  }

  .img-fluid {
    transform: scale(1);
  }
}

///////////////////////////////////////
@media (max-width: 1750px) {
  .container-fluid {
    height: var(--slide-height-1750);
  }
}

///////////////////////////////////////
@media (max-width: 1400px) {
  .container-fluid {
    height: var(--slide-height-1400);
  }
}

///////////////////////////////////////
@media (max-width: 1100px) {
  .container-fluid {
    height: var(--slide-height-1100);
  }
}

///////////////////////////////////////
@media (max-width: 900px) {
  //
}

///////////////////////////////////////
@media (max-width: 767px) {
  .container-fluid {
    flex-direction: column;
    height: var(--slide-height-767);
    justify-content: start;
  }

  .v-application .info {
    width: 100%;

    h1,
    h2 {
      padding-top: 2rem;
    }
  }

  .image {
    width: 100%;

    .img-fluid {
      transform: scale(1);
    }
  }
}

///////////////////////////////////////
@media (max-width: 604px) {
  //
}

///////////////////////////////////////
@media (max-width: 470px) {
  .container-fluid {
    height: var(--slide-height-470);
  }

  .v-application .info {
    padding: 0 1.5rem;
  }
}
</style>
