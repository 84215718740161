<!-- eslint-disable prettier/prettier -->
<template>
    <b-container fluid>
      <div class="title">
        <h2 class="header2">Servicios para empresas y gobierno</h2>
        <p class="text-content">Al conectar el conocimiento con la acción, ayudamos a las empresas a crear experiencias personalizadas para sus clientes y ciudadanos, a generar productos digitales conectados, a optimizar las operaciones y a mejorar la productividad de su personal.</p>
      </div>
      <div class="cards">
        <div class="card-el">
          <b-img class="icon" :src="require('@/assets/analiticaDatos/Bank.webp')" alt="bank"></b-img>
          <h3 class="header3">Gobierno</h3>
          <p class="text-content">
            Soluciones complejas de gobierno a nivel micro: educación, empleo y para el mercado laboral.
          </p>
          <p class="text-content">
            Mapeo de tendencias de crecimiento de mercados y economías estatales o municipales.
          </p>
          <p class="text-content">
           Análisis de oportunidades de negocio, diseño de metodologías de evaluación, diagnósticos, propuestas de fortalecimiento y soluciones.
          </p>
        </div>
        <div class="card-el">
          <b-img class="icon" :src="require('@/assets/analiticaDatos/tower.webp')" alt="company"></b-img>
          <h3 class="header3">Empresa</h3>
          <p class="text-content">
            Conoce el impacto de tus planes de capacitación en la productividad de tu empresa. 
          </p>
          <p class="text-content">
           Conoce detalles del mercado laboral, quiénes son los mejores perfiles para quedarse en la empresa y evitar la rotación.
          </p>
          <p class="text-content">
            Mejora la comprensión de las tendencias del mercado y el comportamiento de tus clientes.
          </p>
          <p class="text-content">
           Optimiza la atención al cliente.
          </p>
        </div>
        <div class="card-el">
          <b-img class="icon" :src="require('@/assets/analiticaDatos/board.webp')" alt="report"></b-img>
          <h3 class="header3">Reportes</h3>
          <p class="text-content">
            Analiza y construye los reportes que aporten más valor a tu negocio. 
          </p>
          <p class="text-content">
            Visualiza gráficamente el avance de tus metas y las de tu organización, por área, departamento o proyecto.
          </p>
          <p class="text-content">
           Facilita el acceso a la información a todos los departamentos y equipos.
          </p>
        </div>
    </div>
    </b-container>
  </template>

<script>
export default {
  name: 'AnaliticaDatosCards',
};
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  flex-direction: column;
  margin: 5rem auto 0 auto;
  width: 70%;
}

.container-fluid {
  max-width: 1920px;
  background: rgb(255, 255, 255);
  padding-top: 7rem;
}
.cards {
  margin-top: 4.5rem;
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-grow: 1;
  text-align: center;
  gap: 3rem;
  .card-el {
    width: 27%;
    padding: 1rem 1.7rem;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.17);
    .icon {
      width: 110px;
      //filter: invert(44%) sepia(81%) saturate(2532%) hue-rotate(210deg)
      //  brightness(99%) contrast(92%);
      margin: 1.7rem 0;
    }
    p {
      font-size: 1.7rem !important;
    }
  }
}

///////////////////////////////////////
@media (max-width: 1750px) {
  .card-el {
    p {
      font-size: 1.5rem !important;
    }
  }
}

///////////////////////////////////////
@media (max-width: 1400px) {
  .cards {
    margin-top: 3rem;
    text-align: center;
    gap: 3rem;
    .card-el {
      width: 40%;
      .icon {
        width: 80px;
      }
    }
  }
}

///////////////////////////////////////
@media (max-width: 1100px) {
  .cards {
    gap: 1.5rem;
    .card-el {
      p {
        font-size: 1.3rem !important ;
      }
    }
  }
}

///////////////////////////////////////
@media (max-width: 900px) {
  .cards {
    gap: 2.5rem;
    .card-el {
      width: 45%;
    }
  }
}

///////////////////////////////////////
@media (max-width: 767px) {
  .cards {
    .card-el {
      width: 80%;
      p {
        font-size: 1.2rem !important;
      }
    }
  }
}

///////////////////////////////////////
@media (max-width: 604px) {
  .title {
    width: 100%;
    //AGREGAR ALGO
  }

  .cards {
    gap: 2rem;
    .card-el {
      width: 90%;
    }
  }
}

///////////////////////////////////////
@media (max-width: 470px) {
  .cards {
    .card-el {
      width: 90%;
      p {
        font-size: 1.1rem !important;
      }
    }
  }
}
</style>
