<!-- eslint-disable prettier/prettier -->
<template>
  <b-navbar class="py-8" type="dark" toggleable="lg">
    <router-link :to="{name : 'home'}">
      <b-navbar-brand>
        <b-img class="logo" :src="require('@/assets/vanglar.png')" alt="logo vanglar"></b-img>
      </b-navbar-brand>
    </router-link>

    <b-navbar-toggle target="nav-collapse">
      <b-icon-list class="hamburger"></b-icon-list>
    </b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>

      <!-- Left aligned nav items 
      <b-navbar-nav class="mr-auto">
        <router-link to="home">
          <b-img class="logo" :src="require('@/assets/vanglar.png')" alt="logo vanglar"></b-img>
        </router-link>
      </b-navbar-nav>
      -->

      <!-- Center aligned nav items -->
      <b-navbar-nav class="mx-auto">
        <b-nav-item>
          <router-link :to="{name : 'home'}">
            INICIO</router-link>
          </b-nav-item>
        <b-nav-item>
          <router-link :to="{name : 'compliance'}">
            COMPLIANCE
          </router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link :to="{name : 'capacitacion'}">
            CAPACITACIÓN
          </router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link :to="{name : 'levantamiento'}">
            LEVANTAMIENTO
          </router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link :to="{name : 'analiticaDatos'}">
            ANALÍTICA DE DATOS
          </router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link :to="{name : 'nosotros'}">
            NOSOTROS
          </router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link :to="{name : 'contacto'}">
            CONTACTO
          </router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link :to="{name : 'blogs'}">
            BLOG
          </router-link>
        </b-nav-item>
        
      </b-navbar-nav>

      <!-- Right aligned nav items -->

      <b-navbar-nav v-if="!user" class="login-container">
        <router-link :to="{name : 'login'}">
          <div class="login">
            <b-icon-person-fill></b-icon-person-fill>LOGIN
          </div>
        </router-link>
      </b-navbar-nav>

      <div v-if="user" class="profile-container">
        <div class="profile" @click="toggleProfileMenu" ref="profile" @mouseover="profileMenu = true" @mouseleave="profileMenu = false">
          <div class="hover-el"></div>
          <span>{{ this.$store.state.profileInitials }}</span>
          <div v-show="profileMenu" class="profile-menu">
            <div class="information">
              <span class="initials">{{ this.$store.state.profileInitials }}</span>
              <div class="right">
                <span>{{ this.$store.state.profileFirstName }} {{ this.$store.state.profileLastName }}</span>
                <span>{{ this.$store.state.profileUsername }}</span>
                <span>{{ this.$store.state.profileEmail }}</span>
              </div>
            </div>
            <div class="options">
              <div class="option">
                <router-link class="option" :to="{name : 'profile'}">
                  Perfil
                </router-link>
              </div>
              <div v-if="admin" class="option">
                <router-link class="option" :to="{name : 'blogsAdmin'}">
                  Admin
                </router-link>
              </div>
              <div @click="signOut" class="option">
                Cerrar sesión
              </div>
            </div>
          </div>
        </div>
      </div>

    </b-collapse>
  </b-navbar>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

export default {
  name: 'NavBar',
  data() {
    return {
      profileMenu: null,
    };
  },
  methods: {
    toggleProfileMenu(e) {
      if (e.target === this.$refs.profile) {
        this.profileMenu = !this.profileMenu;
      }
    },
    signOut() {
      firebase.auth().signOut();
      window.location.reload();
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    admin() {
      return this.$store.state.profileAdmin;
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  font-size: 1rem !important;
}
.v-application a {
  color: white;
}
.navbar {
  background-color: rgba(46, 88, 148, 1) !important;
  //padding-right: 5rem;
}
.v-application a:hover {
  text-decoration: none;
  color: rgb(158, 158, 158) !important;
}

.logo {
  width: 150px;
}
.login-container {
  margin-right: 5rem;
  .login {
    text-align: center;
    color: white;
  }
}

.navbar-toggler {
  border: none;
  .hamburger {
    font-size: 3rem;
    color: white;
  }
}

.v-application ul {
  padding-top: 3rem;
  font-size: 1.2rem;
  text-align: center;
}

.profile-container {
  padding-top: 3rem;
}
.profile {
  margin-right: 3rem;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: white;
  color: rgba(46, 88, 148, 1);
  .hover-el {
    position: absolute;
    cursor: auto;
    //pointer-events: none;
    top: 40px;
    width: 100px;
    height: 30px;
    background-color: rgba(129, 21, 21, 0);
  }
  span {
    pointer-events: none;
  }
  .profile-menu {
    position: absolute;
    z-index: 10;
    top: 60px;
    right: 0;
    width: 300px;
    background-color: white;
    color: #303030;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.17);
    .information {
      display: flex;
      align-items: center;
      padding: 15px;
      .initials {
        position: initial;
        width: 50px;
        height: 50px;
        color: white;
        background-color: rgba(46, 88, 148, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
      .right {
        display: flex;
        flex-direction: column;
        margin-left: 24px;
        span:nth-child(1) {
          font-size: 14px;
        }
        span:nth-child(2),
        span:nth-child(3) {
          font-size: 12px;
        }
      }
    }
    .options {
      padding: 15px;
      .option {
        color: #303030;
        display: flex;
        align-items: center;
        margin-bottom: 12px;
      }
      .option:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 991px) {
  .login-container {
    margin-right: 0;
  }
  .profile {
    margin: 0 auto;
    transform: translate(25%);
    .hover-el {
      top: -20px;
    }
    .profile-menu {
      top: -225px;
      left: 50%;
      transform: translate(-50%);
    }
  }
}
</style>
