<!-- eslint-disable prettier/prettier -->
<template>
  <div class="admin-card">
    <div class="image">
      <b-img :src="post.blogCoverPhoto" alt="content"></b-img>
    </div>
    <router-link class="title" :to="{name: 'viewBlog', params: {blogid: this.post.blogID}}">
      <span>{{post.blogTitle}}</span>
    </router-link>
    <span class="date">{{ new Date(post.blogDate).toLocaleString("es-mx").split(',')[0] }}</span>
    <div class="icon" :class="post.blogPublished ? 'visible' : 'no-visible'">
      <b-icon-eye-fill v-if="post.blogPublished"></b-icon-eye-fill>
      <b-icon-eye-slash-fill v-else></b-icon-eye-slash-fill>
    </div> 
    <div class="icon" @click="editBlog">
      <b-icon-pencil-square></b-icon-pencil-square>
    </div> 
    <div class="icon" @click="dialog=true">
      <b-icon-trash-fill></b-icon-trash-fill>
    </div>
    <v-row justify="center">
      <v-dialog v-model="dialog" width="auto">
        <v-card>
          <v-card-text>
            <p class="pt-10 text-h6">¿Deseas borrar el artículo?</p>
            <p class="font-weight-bold text-h5">{{post.blogTitle}}</p>
            <p class="text-h6">Esta acción es irreversible</p>
          </v-card-text>
          <v-card-actions ctions>
            <v-spacer></v-spacer>
            <v-btn @click="dialog = false">Cancelar</v-btn>
            <v-btn color="primary" @click="deletePost">Borrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'BlogCard',
  props: ['post'],
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    editBlog() {
      this.$router.push({
        name: 'editBlog',
        params: { blogid: this.post.blogID },
      });
    },
    deletePost() {
      this.$store.dispatch('deletePost', this.post.blogID);
      this.dialog = false;
    },
  },
  computed: {
    editPost() {
      return this.$store.state.editPost;
    },
  },
  beforeDestroy() {
    this.$store.commit('toggleEditPost', false);
  },
};
</script>

<style lang="scss" scoped>
.admin-card {
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
  padding: 1rem 0;
  border-bottom: 1px solid rgb(134, 134, 134);

  .image {
    width: 10%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .title {
    width: 70%;
    color: #272727;
    font-size: 1rem !important;
    font-weight: 400;
  }
  .date {
    width: 10%;
    color: #272727;
    font-size: 0.9rem !important;
  }
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5%;
    cursor: pointer;
  }
  .visible {
    cursor: default;
    color: green;
  }
  .no-visible {
    cursor: default;
    color: red;
  }
}
@media (max-width: 1200px) {
  .card {
    width: 80%;
    .description {
      width: 50%;
    }
    .image {
      width: 50%;
    }
  }
}
@media (max-width: 1000px) {
  .card {
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
    width: 80%;
    .description {
      width: 90%;
      gap: 0.5rem;
    }
    .image {
      border-radius: 20px;
      width: 90%;
    }
  }
}
@media (max-width: 570px) {
  .card {
    width: 100%;
    .description {
      width: 100%;
      .short-description {
        width: 100%;
        //max-height: 145px;
      }
    }
    .image {
      width: 100%;
      img {
        //transform: scale(1.3);
      }
    }
  }
}
@media (max-width: 1100px) {
  //
}
@media (max-width: 1100px) {
  //
}
</style>
