<!-- eslint-disable prettier/prettier -->
<template>
  <div class="profile">
    <div class="container">
      <h2 class="header2">Configuración de cuenta</h2>
      <div class="profile-info">
        <div class="initials">
          {{ this.$store.state.profileInitials }}
        </div>
        <div v-if="admin" class="admin-badge">
          <span>admin</span>
        </div>
        <div class="input">
          <label for="firstName">Nombre:</label>
          <input type="text" id="firstName" v-model="firstName" />
        </div>
        <div class="input">
          <label for="lastName">Apellido:</label>
          <input type="text" id="lastName" v-model="lastName" />
        </div>
        <div class="input">
          <label for="username">Username:</label>
          <input type="text" id="username" v-model="username" />
        </div>
        <div class="input">
          <label for="email">Email:</label>
          <input disabled type="text" id="email" v-model="email" />
        </div>
        <b-button @click="updateProfile" size="lg" variant="primary">Guardar Cambios</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  /*
  metaInfo() {
    return {
      title: 'Profile',
      meta: [
        {
          name: 'description',
          content:
            'Vanglar es una aplicación para estudiar diariamente diversos temas que te interesen para prepararte para una evaluación, un examen o una certificación.',
        },
        {
          property: 'og:title',
          content: 'Profile',
        },
        { property: 'og:site_name', content: 'Vanglar' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    };
  },
  */
  data() {
    return {};
  },
  methods: {
    updateProfile() {
      this.$store.dispatch('updateUserSettings');
    },
  },
  computed: {
    admin() {
      return this.$store.state.profileAdmin;
    },
    firstName: {
      get() {
        return this.$store.state.profileFirstName;
      },
      set(payload) {
        this.$store.commit('changeFirstName', payload);
      },
    },
    lastName: {
      get() {
        return this.$store.state.profileLastName;
      },
      set(payload) {
        this.$store.commit('changeLastName', payload);
      },
    },
    username: {
      get() {
        return this.$store.state.profileUsername;
      },
      set(payload) {
        this.$store.commit('changeUsername', payload);
      },
    },
    email: {
      get() {
        return this.$store.state.profileEmail;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  .container {
    max-width: 1000px;
    padding: 60px 25px;
  }
  .profile-info {
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.17);
    padding: 32px;
    background-color: #f1f1f1;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: 32px auto;
    .initials {
      position: initial;
      width: 80px;
      height: 80px;
      font-size: 32px;
      background-color: #303030;
      color: white;
      display: flex;
      align-self: center;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
    .admin-badge {
      display: flex;
      align-self: center;
      color: white;
      font-size: 14px;
      padding: 8px 24px;
      border-radius: 8px;
      background-color: #303030;
      margin: 16px 0;
      text-align: center;
      text-transform: capitalize;
    }
  }
  .input {
    margin: 16px 0;
    .label {
      font-size: 14px;
      display: block;
      padding-bottom: 6px;
    }
    input {
      width: 100%;
      border: none;
      background-color: #ffffff;
      padding: 8px;
      height: 50px;
      @media (min-width: 900px) {
        &:focus {
          outline: none;
        }
      }
    }
  }
}
</style>
