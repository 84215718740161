<!-- eslint-disable prettier/prettier -->
<template>
  <b-container fluid>
    <div class="title">
      <h1 class="header1">Aplicaciones típicas</h1>
      <h2 class="header2">Formularios de alto valor</h2>
    </div>
    <div class="cards">
      <div class="card-el">
        <h3 class="header3">Estudio socioeconómico</h3>
        <p class="text-content">
          Diseñado para la verificación física y corroboración de la identidad de solicitantes de crédito, de empleo, de becas a algún otro servicio.
        </p>
        <p class="text-content">
          La aplicación permite capturar, en tiempo real, datos, firmas, fotografías, y registrar ubicaciones georrefenciadas.
        </p>
        <p class="text-content">
          Los formularios constan de campos de texto abiertos, campos cerrados y mixtos.
        </p>
        <router-link :to="{name : 'contacto'}">
            <b-button size="lg" variant="primary">CONOCE MÁS</b-button>
        </router-link>
      </div>
      <div class="card-el">
        <h3 class="header3">Visita ocular para personas físicas</h3>
        <p class="text-content">
          Recopila información que acredita la operación legal de un negocio, como razón social, fecha de constitución, RFC, etc.
        </p>
        <p class="text-content">
          Captura, en tiempo real, datos, firmas, fotografías, y registra ubicaciones georrefenciadas.
        </p>
        <p class="text-content">
          Los formularios se pueden personalizar de acuerdo con las necesidades de la empresa.
        </p>
        <router-link :to="{name : 'contacto'}">
            <b-button size="lg" variant="primary">CONOCE MÁS</b-button>
        </router-link>
      </div>
      <div class="card-el">
        <h3 class="header3">Visita ocular presencial</h3>
        <p class="text-content">
          Tiene una función dual: permite registrar la inspección ocular de un domicilio particular y/o del lugar de empleo de una persona.
        </p>
        <p class="text-content">
          Es una verificación de la existencia, ubicación y condiciones del domicilio o sitio de trabajo, a partir de referencias físicas, documentales y de entrevistas con vecinos.
        </p>
        <p class="text-content">
          Envíalo por correo y conviértelo a PDF.
        </p>
        <router-link :to="{name : 'contacto'}">
            <b-button size="lg" variant="primary">CONOCE MÁS</b-button>
        </router-link>
      </div>
  </div>
  </b-container>
</template>

<script>
export default {
  name: 'LevantamientoCards',
};
</script>

<style lang="scss" scoped>
.container-fluid {
  max-width: 1920px;
  background: rgb(255, 255, 255);
  padding-top: 2rem;
}
.cards {
  margin-top: 4.5rem;
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-grow: 1;
  text-align: center;
  gap: 3rem;
  .card-el {
    width: 27%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 4rem 1.7rem;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.17);
    .icon {
      width: 110px;
      filter: invert(44%) sepia(81%) saturate(2532%) hue-rotate(210deg)
        brightness(99%) contrast(92%);
      margin: 1.7rem 0;
    }
    p {
      font-size: 1.7rem !important;
    }
  }
}

///////////////////////////////////////
@media (max-width: 1750px) {
  .card-el {
    p {
      font-size: 1.5rem !important;
    }
  }
}

///////////////////////////////////////
@media (max-width: 1400px) {
  .cards {
    margin-top: 3rem;
    text-align: center;
    gap: 3rem;
    .card-el {
      width: 40%;
      .icon {
        width: 80px;
      }
    }
  }
}

///////////////////////////////////////
@media (max-width: 1100px) {
  .cards {
    gap: 1.5rem;
    .card-el {
      p {
        font-size: 1.3rem !important ;
      }
    }
  }
}

///////////////////////////////////////
@media (max-width: 900px) {
  .cards {
    gap: 2.5rem;
    .card-el {
      width: 45%;
    }
  }
}

///////////////////////////////////////
@media (max-width: 767px) {
  .cards {
    .card-el {
      width: 80%;
      p {
        font-size: 1.2rem !important;
      }
    }
  }
}

///////////////////////////////////////
@media (max-width: 604px) {
  .title {
    width: 100%;
    //AGREGAR ALGO
  }

  .cards {
    gap: 2rem;
    .card-el {
      width: 90%;
    }
  }
}

///////////////////////////////////////
@media (max-width: 470px) {
  .cards {
    .card-el {
      width: 90%;
      p {
        font-size: 1.1rem !important;
      }
    }
  }
}
</style>
