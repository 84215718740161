/* eslint-disable prettier/prettier */
import Vue from 'vue';
import { createHead, HeadVuePlugin } from "@vueuse/head"
import VueMeta from 'vue-meta';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
//import 'firebase/compat/firestore';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import Vue2Editor from 'vue2-editor';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import firebaseInit from './assets/firebase/firebaseInit';

import { ObserveVisibility } from 'vue-observe-visibility';

import Pagination from 'vue-pagination-2';

Vue.directive('observe-visibility', ObserveVisibility);

const head = createHead()

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(HeadVuePlugin, head)
Vue.use(head)
Vue.use(VueMeta)
Vue.use(Vue2Editor);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('pagination', Pagination);

Vue.config.productionTip = false;

/*
const firebaseConfig = {
  apiKey: 'AIzaSyA7LqbYCdxuL6gxWIYESb0iVFGEGLjv-Hw',
  authDomain: 'vanglar-com.firebaseapp.com',
  databaseURL: 'https://vanglar-com.firebaseio.com',
  projectId: 'vanglar-com',
  storageBucket: 'vanglar-com.appspot.com',
  messagingSenderId: '465341893897',
  appId: '1:465341893897:web:ef70793d81eae94aa8d7d8',
};
*/

//firebase.initializeApp(firebaseConfig);
firebaseInit;

let app;
firebase.auth().onAuthStateChanged(() => {
    if (!app) {
        new Vue({
            router,
            store,
            vuetify,
            render: (h) => h(App),
        }).$mount('#app');
    }
});
