<!-- eslint-disable prettier/prettier -->
<template>
  <div class="container" v-if="admin">
    <div class="new-post">
      <router-link to="create-post">
        <b-button @click="clearContent" size="lg" variant="primary">
          + Crear blog
        </b-button>
      </router-link>
    </div>
    <div class="blogs-container">
      <div class="cards-container">
        <AdminBlogCard :post="post" v-for="(post, index) in this.$store.state.blogPosts" :key="index"></AdminBlogCard>
      </div>
    </div>
  </div>
</template>

<script>
import AdminBlogCard from '@/components/blog/AdminBlogCard.vue';

export default {
  /*
  metaInfo() {
    return {
      title: 'Blogs',
      meta: [
        {
          name: 'description',
          content:
            'Vanglar es una aplicación para estudiar diariamente diversos temas que te interesen para prepararte para una evaluación, un examen o una certificación.',
        },
        {
          property: 'og:title',
          content: 'Blogs',
        },
        { property: 'og:site_name', content: 'Vanglar' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    };
  },
  */
  components: {
    AdminBlogCard,
  },
  methods: {
    clearContent() {
      const emptyBlog = {
        blogTitle: null,
        blogHTML: null,
        blogTags: null,
        blogAuthor: null,
        blogShortDescription: null,
        blogPublished: null,
        blogCoverPhoto: null,
        blogCoverPhotoName: null,
      };
      this.$store.commit('setBlogState', emptyBlog);
    },
  },
  computed: {
    admin() {
      return this.$store.state.profileAdmin;
    },
    blogFeaturedCard() {
      return this.$store.getters.blogFeaturedCard;
    },
    blogPostsCards() {
      return this.$store.getters.blogPostsCards;
    },
    editPost: {
      get() {
        return this.$store.state.editPost;
      },
      set(payload) {
        this.$store.commit('toggleEditPost', payload);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.new-post {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
}
.blogs-container {
  display: flex;
  //gap: 2rem;
  .cards-container {
    width: 100%;
    //width: 75%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .sidebar {
    background-color: aquamarine;
    width: 25%;
  }
}
@media (max-width: 767px) {
  .toggle-edit {
    margin-top: 1rem;
    justify-content: center;
    transform: scale(0.8);
  }
}
</style>
