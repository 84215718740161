<!-- eslint-disable prettier/prettier -->
<template>
  <div class="container-view">
    <div class="featured-card">
      <FeaturedCard :post="post" v-for="(post, index) in blogFeaturedCard" :key="index"></FeaturedCard>
    </div>
    <div class="blogs">
      <div class="container blogs-container">
      <!--
        <div class="cards-container">
          <BlogCard :post="post" v-for="(post, index) in blogPostsCards" :key="index"></BlogCard>
              </div>-->
        <div class="cards-container">
          <h3 v-if="blogNotFound">No hay resultados para esta búsqueda</h3>
          <BlogCard :post="post" v-for="(post, index) in displayedBlogs" :key="index"></BlogCard>
          <pagination :records="blogsNumber" v-model="page" :per-page="perPage" @paginate="changePage"
            :options="{ texts: { count: '' }, edgeNavigation: false }" />
        </div>

      </div>
      <div v-if="true" class="sidebar">
        <div class="search-container">
          <h4>Buscar</h4>
          <div class="input-field">
            <input class="search" type="text" placeholder="Buscar..." v-model="search">
            <b-icon-search></b-icon-search>
          </div>
        </div>
        <div class="category-container">
          <h4>Categoría</h4>
          <div class="category-items">
            <CategoryItem :active="category === categorySelected" :category="category" :setCategory="setCategory" v-for="(category, index) in Object.keys(blogCategories)" :key="index"></CategoryItem>
            <button v-if="search || categorySelected" class="clear-filters" @click="clearFilter">Limpiar filtros</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FeaturedCard from '@/components/blog/FeaturedCard.vue';
import BlogCard from '@/components/blog/BlogCard.vue';
import CategoryItem from '@/components/blog/CategoryItem.vue';

/*
import { useHead } from '@vueuse/head';


useHead({
  title: 'Vanglar Homepage',
  meta: [
    {
      name: 'description',
      content: 'Homepage description',
    },
    {
      name: 'og:title',
      content: 'Vanglar test',
    },
  ],
});
*/

export default {
  /*
  metaInfo() {
    return {
      title: 'Blogs',
      meta: [
        {
          name: 'description',
          content:
            'Vanglar es una aplicación para estudiar diariamente diversos temas que te interesen para prepararte para una evaluación, un examen o una certificación.',
        },
        {
          property: 'og:title',
          content: 'Blogs title test',
        },
        { property: 'og:site_name', content: 'Vanglar title test' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    };
  },
  */
  data() {
    return {
      page: 1,
      perPage: 4,
      search: '',
      blogsNumber: 0,
      blogNotFound: false,
      categorySelected: '',
    };
  },
  components: {
    FeaturedCard,
    BlogCard,
    CategoryItem,
  },
  created() {
    this.blogsNumber = this.blogPostsCards.length;
  },
  methods: {
    setCategory(category) {
      if (category === this.categorySelected) {
        this.categorySelected = '';
      } else {
        this.categorySelected = category;
      }
    },
    clearFilter() {
      this.categorySelected = '';
      this.search = '';
    },
    changePage(page) {
      return page;
    },
    changeBlogNumber(number) {
      this.blogsNumber = number;
    },
    displayNotFound(display) {
      this.blogNotFound = display;
    },
    clearContent() {
      const emptyBlog = {
        blogTitle: null,
        blogHTML: null,
        blogTags: null,
        blogAuthor: null,
        blogShortDescription: null,
        blogPublished: null,
        blogCoverPhoto: null,
        blogCoverPhotoName: null,
      };
      this.$store.commit('setBlogState', emptyBlog);
    },
    formatString(inputString) {
      return inputString
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    },
  },
  computed: {
    admin() {
      return this.$store.state.profileAdmin;
    },
    blogFeaturedCard() {
      return this.$store.getters.blogFeaturedCard;
    },
    blogCategories() {
      return this.$store.state.blogCategories;
    },
    blogPostsCards() {
      return this.$store.getters.blogPostsCards;
    },
    editPost: {
      get() {
        return this.$store.state.editPost;
      },
      set(payload) {
        this.$store.commit('toggleEditPost', payload);
      },
    },
    displayedBlogs() {
      const searchString = this.formatString(this.search);

      let categoryResults = [];
      if (this.categorySelected) {
        this.blogPostsCards.forEach((blog) => {
          if (this.categorySelected === blog.blogCategory) {
            categoryResults.push(blog);
          }
        });
      } else {
        categoryResults = this.blogPostsCards;
      }

      if (searchString) {
        let searchResults = [];
        categoryResults.forEach((blog) => {
          let author = this.formatString(blog.blogAuthor);
          let title = this.formatString(blog.blogTitle);
          let tags = this.formatString(blog.blogTags.join(' '));
          if (
            author.includes(searchString) ||
            title.includes(searchString) ||
            tags.includes(searchString)
          ) {
            searchResults.push(blog);
          }
        });
        const startIndex = this.perPage * (this.page - 1);
        const endIndex = startIndex + this.perPage;
        this.changeBlogNumber(searchResults.length);
        if (searchResults.length <= 0) {
          this.displayNotFound(true);
        } else {
          this.displayNotFound(false);
        }
        return searchResults.slice(startIndex, endIndex);
      } else {
        if (categoryResults.length <= 0) {
          this.displayNotFound(true);
        } else {
          this.displayNotFound(false);
        }
        this.changeBlogNumber(categoryResults.length);
        const startIndex = this.perPage * (this.page - 1);
        const endIndex = startIndex + this.perPage;
        return categoryResults.slice(startIndex, endIndex);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container-view {
  margin: 0 auto;
  max-width: 1920px;
  width: 100%;

  .blogs {
    display: flex;
    margin: 4rem 5rem;

    .blogs-container {
      //width: 70%;
      display: flex;
      gap: 2rem;

      .cards-container {
        width: 100%;
        //width: 75%;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 3rem;
      }
    }

    .sidebar {
      display: flex;
      flex-direction: column;
      gap: 4rem;
      //background-color: rgb(245, 245, 245);
      width: 300px;
      //height: 400px;
      padding: 1rem;

      .input-field {
        margin-top: 1rem;
        width: 90%;
        border-radius: 2px;
        border: 1px solid rgb(218, 218, 218);

        input {
          padding: 0.5rem 0.5rem;
          width: 90%;
        }

        input:focus {
          outline: none;
        }
      }
    }
  }

  .featured-card {
    //
  }
}

@media (max-width: 1000px) {
  .blogs {
    flex-direction: column-reverse;

    .sidebar {
      align-self: center;
    }
  }
}

@media (max-width: 767px) {
  .toggle-edit {
    margin-top: 1rem;
    justify-content: center;
    transform: scale(0.8);
  }

  .container-view {
    .blogs {
      margin: 0;
    }
  }
}

.page-item:last-child {
  border-bottom-right-radius: 0rem !important;
}

.category-container {
  gap: 2rem;

  .category-items {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .category-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      .name {
        color: #84858a;
        font-size: 1.1rem;
        width: 100%;
      }

      .number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30%;
        height: 25px;
        border-radius: 3px;
        color: white;
        font-size: 0.9rem;
        background-color: #46b8ff;
      }
    }
    .clear-filters {
      margin-top: 2rem;
      background-color: #0b2653;
      color: white;
      width: 60%;
      padding: 0.3rem 0;
      border-radius: 10px;
    }
  }
}
</style>
