<!-- eslint-disable prettier/prettier -->
<template>
  <div>
    <HeroComponent title="Capacitación" description="Transforma la manera en la que tu personal aprende."
      image="capacitacion/capacitacion1.png" :descriptionLeft=true>
      <template v-slot:extra>
        <router-link :to="{ name: 'contacto' }">
          <b-button size="lg" variant="primary">CONOCE MÁS</b-button>
        </router-link>
      </template>
    </HeroComponent>

    <div class="title">
      <h1 class="header1">Soluciones Vanglar</h1>
      <p class="text-content">Aprende en cualquier lugar, en cualquier momento, es fácil con soluciones de
        microaprendizaje y con procesos de evaluación y retroalimentación inmediatos.</p>
    </div>

    <SlideComponent subtitle="Eficacia y sencillez"
      description="La capacitación diaria es una realidad con Vanglar. Nuestro sistema de micro capacitación asegura una base de conocimiento de la organización."
      image="capacitacion/capacitacion2.png" :descriptionLeft=false height="800">
      <template v-slot:extra>
        <p class="text-content pt-2">Además, la integración de la teoría con la práctica favorece el desarrollo de
          capacidades como el análisis de problemas y el desarrollo de soluciones participativas y viables</p>
      </template>
    </SlideComponent>

    <SlideComponent subtitle="Analítica sencilla y poderosa"
      description="Identifica de un vistazo quiénes son candidatos a ascensos, a premiación por esfuerzo, quiénes deben cambiar de enfoque. Todo, de forma rápida y sencilla, con la propuesta de análisis de datos de Vanglar."
      image="capacitacion/capacitacion3.png" :descriptionLeft=true height="800">
    </SlideComponent>

    <SlideComponent subtitle="Identificación de oportunidades"
      description="Realiza cortes de conocimiento por territorio, zonas, años de antigüedad, sexo o posición en el escalafón, e identifica las tendencias y los patrones de entrenamiento para diseñar programas de capacitación dirigidos a las áreas que más lo necesitan."
      button="CONOCE MÁS" image="capacitacion/capacitacion4.png" :descriptionLeft=false height="800">
    </SlideComponent>

    <MiniSlides></MiniSlides>

    <HeroComponent title="Nuestro trabajo"
      description="Creados por especialistas en auto-aprendizaje y aprendizaje remoto, los contenidos de capacitación se diseñan con base en las necesidades de la empresa para asegurar el cumplimiento de los objetivos y las metas."
      image="capacitacion/capacitacion5.png" :descriptionLeft=true height="800">
    </HeroComponent>

  </div>
</template>

<script>
import HeroComponent from '@/components/general/HeroComponent.vue';
import SlideComponent from '@/components/general/SlideComponent.vue';
import MiniSlides from '@/components/capacitacion/MiniSlides.vue';

export default {
  /*
  metaInfo() {
    return {
      title: 'Capacitacion',
      meta: [
        {
          name: 'description',
          content:
            'Vanglar es una aplicación para estudiar diariamente diversos temas que te interesen para prepararte para una evaluación, un examen o una certificación.',
        },
        {
          property: 'og:title',
          content: 'Capacitacion',
        },
        { property: 'og:site_name', content: 'Vanglar' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    };
  },
  */
  components: {
    HeroComponent,
    SlideComponent,
    MiniSlides,
  },
};
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  flex-direction: column;
  margin: 5rem auto 0 auto;
  width: 50%;
}

@media (max-width: 767px) {
  .title {
    width: 90%;
  }
}

@media (max-width: 470px) {
  .title {
    margin-top: 2rem;
  }
}
</style>
