<!-- eslint-disable prettier/prettier -->
<template>
  <b-container fluid>
    <div class="slides">
      <h1 class="header1">Servicios</h1>
      <div class="mini-slide">
        <div class="image">
          <b-img fluid :src="require('@/assets/capacitacion/servicios1.jpg')" alt="content"></b-img>
        </div>
        <div class="description">
          <h3 class="header3">Guías y cursos</h3>
          <p class="text-content">Diseño instruccional y desarrrollo de guiones</p>
          <router-link :to="{name : 'contacto'}">
            <b-button size="lg" variant="primary">CONTÁCTANOS</b-button>
          </router-link>
        </div>
      </div>

      <div class="mini-slide reversed">
        <div class="image">
          <b-img fluid :src="require('@/assets/capacitacion/servicios2.jpg')" alt="content"></b-img>
        </div>
        <div class="description">
          <h3 class="header3">Programación</h3>
          <p class="text-content">Entrega de productos en SCORM, HTML 5, Java</p>
          <router-link :to="{name : 'contacto'}">
            <b-button size="lg" variant="primary">CONTÁCTANOS</b-button>
          </router-link>
        </div>
      </div>

      <div class="mini-slide">
        <div class="image">
          <b-img fluid :src="require('@/assets/capacitacion/servicios3.jpg')" alt="content"></b-img>
        </div>
        <div class="description">
          <h3 class="header3">Multimedia</h3>
          <p class="text-content">Producción de video, podcasts y animaciones</p>
          <router-link :to="{name : 'contacto'}">
            <b-button size="lg" variant="primary">CONTÁCTANOS</b-button>
          </router-link>
        </div>
      </div>

      <div class="mini-slide reversed">
        <div class="image">
          <b-img fluid :src="require('@/assets/capacitacion/servicios4.jpg')" alt="content"></b-img>
        </div>
        <div class="description">
          <h3 class="header3">Complementarios</h3>
          <p class="text-content">Traducción y subtitulaje</p>
          <router-link :to="{name : 'contacto'}">
            <b-button size="lg" variant="primary">CONTÁCTANOS</b-button>
          </router-link>
        </div>
      </div>
    </div>  
  </b-container>
</template>

<script>
export default {
  name: 'MiniSlides',
};
</script>

<style lang="scss" scoped>
.container-fluid {
  overflow: hidden;
  max-width: 1920px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.slides {
  margin: 5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5rem;
  .mini-slide {
    display: flex;
    padding: 2rem;
    width: 70%;
    border-radius: 10px;
    gap: 2rem;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.17);
    .image {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .description {
      padding: 0 1.5rem;
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p {
        color: #7c7c7c !important;
      }
    }
  }
}

.reversed {
  flex-direction: row-reverse;
}

///////////////////////////////////////
@media (max-width: 1750px) {
}

///////////////////////////////////////
@media (max-width: 1400px) {
}

///////////////////////////////////////
@media (max-width: 1100px) {
}

///////////////////////////////////////
@media (max-width: 900px) {
  .slides {
    .mini-slide {
      flex-direction: column;
      width: 80%;
      .image {
        width: 100%;
      }
      .description {
        width: 100%;
      }
    }
  }
}

///////////////////////////////////////
@media (max-width: 767px) {
  .slides {
    gap: 2rem;
  }
}

///////////////////////////////////////
@media (max-width: 604px) {
}

///////////////////////////////////////
@media (max-width: 470px) {
  .slides {
    .mini-slide {
      width: 90%;
      .description {
        width: 100%;
      }
    }
  }
}
</style>
