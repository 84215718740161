<!-- eslint-disable prettier/prettier -->
<template>
  <b-container fluid>
    <b-img class="back-img" :src="require('@/assets/home/contact-img.png')"></b-img>
    <div class="bubble" v-observe-visibility="bounceBubble">
      <h1 class="header1">Conócenos</h1>
      <div class="triangle"></div>
    </div>
    <h1 class="title-show">Conócenos</h1>
    <b-form class="form">
      <b-form-row class="full-name">
        <b-col><b-form-input v-model="name" class="font-weight-bold" placeholder="Nombre*" /></b-col>
        <b-col><b-form-input v-model="lastname" class="font-weight-bold" placeholder="Apellido*"/></b-col>
      </b-form-row>
      <b-form-input v-model="email" class="font-weight-bold" placeholder="Email*"/>
      <b-form-textarea v-model="message" class="mt-11" rows="6" placeholder="¿Cómo podemos ayudarte?"/>
      <b-button class="w-100" variant="primary" @click="sendEmail">ENVIAR</b-button>
    </b-form>
    <!--Snack de anuncio-->
    <v-snackbar
        v-model="snackbar"
        centered
        color="primary"
        timeout="2000"
      >
        {{ textConfirmation }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="black"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            X
          </v-btn>
        </template>
      </v-snackbar>
  </b-container>
</template>

<script>
export default {
  name: 'HomeContact',
  data() {
    return {
      name: '',
      lastname: '',
      message: '',
      email: '',
      textConfirmation: 'El correo fue enviado',
      valid: false,
      buttonValid: false,
      snackbar: false,
    };
  },
  methods: {
    bounceBubble(isVisible, entry) {
      if (isVisible) {
        // trigger animation for the element
        let domElement = entry.target;
        domElement.classList.add('bounceBubble');
      }
    },

    sendEmail() {
      if (this.name != '' && this.email != '' && this.message != '') {
        try {
          fetch('https://formsubmit.co/ajax/victor.quiroz@vanglar.com', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
            body: JSON.stringify({
              name: this.name,
              lastname: this.lastname,
              message: this.message,
              email: this.email,
            }),
          })
            .then((response) => response.json())
            .then((data) => {
              console.log(data.success);
              if (data.success == 'true') {
                this.textConfirmation = 'El correo fue enviado';
                this.snackbar = true;
              } else {
                this.textConfirmation = 'No se pudo enviar el correo';
                this.snackbar = true;
                this.clear();
              }
            });
        } catch (error) {
          console.log({ error });
        }
      } else {
        this.textConfirmation = 'No se pudo enviar el correo';
        this.snackbar = true;
      }
    },
    clear() {
      this.name = '';
      this.lastname = '';
      this.message = '';
      this.email = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
  margin-top: 10rem;
  max-width: 1920px;
  padding: 6rem 7rem;
  position: relative;
}

.title-show {
  display: none;
}
.form {
  width: 56.5%;
  margin-left: auto;
  padding: 3rem 4.5rem 5.5rem 4.5rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.17);
  .form-control {
    margin: 1.8rem 0;
    padding: 2.5rem 2rem;
    font-size: 1.5rem;
    background-color: #f7f8fa;
    border: none;
  }
  .full-name {
    gap: 2rem;
  }

  .btn-primary {
    margin-top: 1rem;
    padding: 1.5rem 0;
    font-size: 1.4rem;
    background-color: #43bafe;
    border: none;
  }
}

.back-img {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 32%;
}

.bubble {
  opacity: 0;
  position: absolute;
  top: 18%;
  left: 17%;
  padding: 1.5rem 2rem;
  background-color: #0b2653;
  border-radius: 20px;
  //background-image: url('@/assets/bubble.png');
  //background-size: 100% 100%;
  h1 {
    color: white !important;
    font-size: 5rem !important;
  }
  .triangle {
    position: absolute;
    top: 99%;
    left: 15%;
    width: 0;
    height: 0;
    border: 40px solid transparent;
    border-bottom: 0;
    border-top: 35px solid #0b2653;
  }
}

@media (max-width: 1750px) {
  .back-img {
    width: 38%;
  }
  .bubble {
    top: 15%;
    left: 15%;
    h1 {
      font-size: 4.5rem !important;
    }
  }
}

@media (max-width: 1500px) {
  .bubble {
    top: 20%;
    left: 13%;
  }
}

@media (max-width: 1400px) {
  .back-img {
    width: 42%;
  }
  .bubble {
    top: 20%;
    left: 12%;
    h1 {
      font-size: 4rem !important;
    }
    .triangle {
      border: 35px solid transparent;
      border-top: 30px solid #0b2653;
    }
  }
}

@media (max-width: 1300px) {
  .back-img {
    width: 45%;
  }
  .bubble {
    top: 25%;
    left: 10%;
  }
}

@media (max-width: 1100px) {
  .form {
    width: 70%;
    .form-control {
      font-size: 1.2rem;
    }
  }

  .back-img {
    width: 45%;
    transform: translateX(-30%);
  }

  .bubble {
    top: 35%;
    left: 1%;
    h1 {
      font-size: 3rem !important;
    }
  }
}

@media (max-width: 900px) {
  .container-fluid {
    margin-top: 5rem;
  }
  .form {
    width: 100%;
    .btn-primary {
      margin-top: 1rem;
      padding: 1.2rem 0;
      font-size: 1.2rem;
      background-color: #43bafe;
      border: none;
    }
  }

  .back-img {
    display: none;
  }

  .bubble {
    display: none;
  }

  .title-show {
    display: block;
    margin-bottom: 2rem;
    text-align: center;
    font-size: 4rem;
    font-weight: 800;
    color: #1c1d70;
  }
}

@media (max-width: 767px) {
  .container-fluid {
    padding: 6rem 4rem;
  }
}

@media (max-width: 604px) {
  .container-fluid {
    padding: 6rem 2rem;
  }

  .title-show {
    font-size: 3rem;
  }
  .form {
    padding: 2rem 2rem;
    .form-control {
      font-size: 1rem;
    }
    .full-name {
      gap: 1rem;
    }
  }
}

@media (max-width: 470px) {
  .form {
    .form-control {
      padding: 1.5rem 1rem;
      margin: 1rem 0;
    }
  }
}

@media (max-width: 377px) {
  .container-fluid {
    padding: 6rem 1rem;
  }
  .title-show {
    font-size: 2.5rem;
  }

  .form {
    padding: 1rem 1rem;
    .btn-primary {
      font-size: 1rem;
      padding: 0.5rem 0;
    }
  }
}
</style>
