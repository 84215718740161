<!-- eslint-disable prettier/prettier -->
<template>
  <b-container fluid>
    <div class="title">
      <h1 class="header1">Herramientas Vanglar</h1>
      <h2 class="header2">Son garantía de:</h2>
    </div>
    <div class="cards">
      <div class="card-el">
        <b-img class="icon" :src="require('@/assets/compliance/icono-cabeza.png')" alt="development"></b-img>
        <h3 class="header3">Facilidad de uso</h3>
        <p class="text-content">Habilitan la colaboración de todos los integrantes de la empresa en el control del cumplimiento en función de su rol asignado, incluyendo socios, proveedores y clientes.</p>
      </div>
      <div class="card-el">
        <b-img class="icon" :src="require('@/assets/compliance/icono-reloj.png')" alt="rating"></b-img>
        <h3 class="header3">Tiempo real</h3>
        <p class="text-content">La información se captura y comparte de forma inmediata. Registra, revisa y analiza tus avances y pendientes; programa alertas de cumplimiento y genera reportes de estatus.</p>
      </div>
      <div class="card-el">
        <b-img class="icon" :src="require('@/assets/compliance/icono-cajafuerte.png')" alt="document"></b-img>
        <h3 class="header3">Seguridad y confidencialidad</h3>
        <p class="text-content">Protege la información de tu empresa, de tus clientes y empleados de accesos no autorizados, corrupción o robo. Además, la información puede ser encriptada para máxima seguridad.</p>
      </div>
      <div class="image-container">
        <b-img class="image" :src="require('@/assets/compliance/compliance6.png')" alt="dashboard"></b-img>
      </div>
      <div class="card-el">
        <b-img class="icon" :src="require('@/assets/compliance/icono-compu.png')" alt="map"></b-img>
        <h3 class="header3">Completitud</h3>
        <p class="text-content">Tus datos estarán completos y podrán cruzarse con diferentes bases de datos para apoyarte en la toma de decisones.</p>
      </div>
      <div class="card-el">
        <b-img class="icon" :src="require('@/assets/compliance/icono-flecha.png')" alt="dashboard"></b-img>
        <h3 class="header3">Personalización integral</h3>
        <p class="text-content">Todas las soluciones son trajes a la medida para tu empresa y están orientadas a la automatización.</p>
      </div>
  </div>
  </b-container>
</template>

<script>
export default {
  name: 'ComplianceCards',
};
</script>

<style lang="scss" scoped>
.container-fluid {
  overflow: hidden;
  max-width: 1920px;
  height: 1900px;
  background: rgb(255, 255, 255);
  padding-top: 4rem;
}
.cards {
  margin-top: 4.5rem;
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-grow: 1;
  text-align: center;
  gap: 4rem;
  .card-el {
    width: 28%;
    padding: 1rem 4rem;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.17);
    .icon {
      width: 110px;
      //filter: invert(44%) sepia(81%) saturate(2532%) hue-rotate(210deg)
      //  brightness(99%) contrast(92%);
      margin: 1.7rem 0;
    }
    p {
      color: #6d6d6d;
      font-size: 1.7rem !important;
    }
  }
  .image-container {
    position: relative;
    padding: 1rem 1.7rem;
    width: 28%;
    .image {
      position: absolute;
      width: 100%;
      top: 0;
      left: -10%;
      transform: scale(1.2);
    }
  }
}

///////////////////////////////////////
@media (max-width: 1750px) {
  .card-el {
    p {
      font-size: 1.5rem !important;
    }
  }
}

///////////////////////////////////////
@media (max-width: 1400px) {
  .container-fluid {
    height: 2200px;
  }
  .cards {
    margin-top: 3rem;
    text-align: center;
    gap: 3rem;
    .card-el {
      width: 40%;
      .icon {
        width: 80px;
      }
    }
    .image-container {
      width: 40%;
      order: 1;
      .image {
        transform: scale(1);
      }
    }
  }
}

///////////////////////////////////////
@media (max-width: 1100px) {
  .container-fluid {
    height: 2100px;
  }
  .cards {
    gap: 1.5rem;
  }
}

///////////////////////////////////////
@media (max-width: 900px) {
  .container-fluid {
    height: auto;
  }
  .cards {
    gap: 2.5rem;
    .card-el {
      width: 70%;
    }
    .image-container {
      width: 70%;
      .image {
        position: relative;
      }
    }
  }
}

///////////////////////////////////////
@media (max-width: 767px) {
  .cards {
    .card-el {
      p {
        font-size: 1.2rem !important;
      }
    }
  }
}

///////////////////////////////////////
@media (max-width: 604px) {
  .title {
    width: 100%;
    //AGREGAR ALGO
  }

  .cards {
    gap: 2rem;
    .card-el {
      width: 80%;
    }
    .image-container {
      width: 80%;
    }
  }
}

///////////////////////////////////////
@media (max-width: 470px) {
  .cards {
    .card-el {
      width: 90%;
      p {
        font-size: 1.1rem !important;
      }
    }
    .image-container {
      width: 90%;
    }
  }
}
</style>
