<!-- eslint-disable prettier/prettier -->
<template>
  <HeroComponent title="Vanglar" description="Capacitación, compliance y soluciones digitales para resultados exponenciales" image="home/home1.png" :descriptionLeft=true>
    <template v-slot:extra>
      <div class="downloads">
      <a target="_blank" class="appstore" href="https://apps.apple.com/us/app/vanglar-wp/id1570157235"></a>
      <a target="_blank" class="playstore" href="https://play.google.com/store/apps/details?id=com.vanglar.vanglar_flutter&pli=1"></a>
    </div>
    </template>
  </HeroComponent>
</template>

<script>
import HeroComponent from '@/components/general/HeroComponent.vue';
export default {
  name: 'HomeHero',
  props: ['title', 'description', 'image', 'descriptionLeft'],
  components: { HeroComponent },
};
</script>

<style lang="scss" scoped>
.downloads {
  padding-top: 2rem;
  display: flex;
  justify-content: center;
  //gap: 1.8rem;
  gap: 5.5%;
  .appstore {
    //padding: 2.11rem 6.3rem;
    padding: 6.5% 19.5%;
    background-image: url('@/assets/appstore.svg');
    background-size: cover;
  }
  .playstore {
    //padding: 2.11rem 7.2rem;
    padding: 6.5% 22%;
    background-image: url('@/assets/playstore.png');
    background-size: cover;
  }
}

///////////////////////////////////////
@media (max-width: 1750px) {
  //
}
///////////////////////////////////////
@media (max-width: 1400px) {
  //
}

///////////////////////////////////////
@media (max-width: 1100px) {
  //
}

///////////////////////////////////////
@media (max-width: 900px) {
  .downloads {
    padding-top: 0.5rem;
    .appstore {
      //padding: 6.5% 19.5%;
      padding: 9.75% 29.25%;
    }
    .playstore {
      //padding: 6.5% 22%;
      padding: 9.75% 33%;
    }
  }
}

///////////////////////////////////////
@media (max-width: 767px) {
  .downloads {
    .appstore {
      padding: 1.69rem 5.04rem;
    }
    .playstore {
      padding: 1.69rem 5.76rem;
    }
  }
}

///////////////////////////////////////
@media (max-width: 604px) {
  .downloads {
    .appstore {
      padding: 1.69rem 5.04rem;
    }
    .playstore {
      padding: 1.69rem 5.76rem;
    }
  }
}

///////////////////////////////////////
@media (max-width: 470px) {
  .downloads {
    .appstore {
      padding: 1.35rem 4.03rem;
    }
    .playstore {
      padding: 1.35rem 4.6rem;
    }
  }
}
</style>
