<!-- eslint-disable prettier/prettier -->
<template>
    <b-container fluid>
      <div class="cards">
        <div class="card-el">
            <v-row class="mt-8">
                <v-col cols="3" class="h1">
                    <b-icon-telephone></b-icon-telephone>
                </v-col>
                <v-col cols="9">
                    <h3 class="header3 black--text">Teléfono</h3>
                    <h5 class="text--disabled">
                        Tel: +52 55 1370 7787
                    </h5>
                </v-col>
            </v-row>
        </div>
        <div class="card-el">
          <v-row class="mt-7">
              <v-col cols="3" class="h1">
                  <b-icon-geo-alt></b-icon-geo-alt>
              </v-col>
              <v-col cols="9">
                  <h3 class="header3 black--text">Dirección</h3>
                  <h5 class="text--disabled">
                    Av. Patriotismo 767, piso 6, San Juan, 03730, CDMX
                  </h5>
              </v-col>
          </v-row>
        </div>
        <div class="card-el">
          <v-row class="mt-7">
              <v-col cols="3" class="h1">
                  <b-icon-envelope></b-icon-envelope>
              </v-col>
              <v-col cols="9">
                  <h3 class="header3 black--text">Email</h3>
                  <span class="text--disabled text-sm-h6 emailOverflow">
                    victor.quiroz@vanglar.com
                  </span>
              </v-col>
          </v-row>
        </div>
    </div>
    </b-container>
  </template>

<script>
export default {
  name: 'ContactoCards',
};
</script>

<style lang="scss" scoped>
.emailOverflow {
  font-family: 'BergenSans' !important;
}
.name {
  color: 'black' !important;
}
.b-icon.bi {
  color: #43baff;
  margin: 0 1rem;
}
.cards {
  margin-top: 4.5rem;
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-grow: 1;
  text-align: left;
  gap: 3rem;
  .card-el {
    width: 27%;
    padding: 1rem 1.7rem;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.17);
    p {
      font-size: 1.7rem !important;
    }
  }
}

///////////////////////////////////////
@media (max-width: 1750px) {
  .card-el {
    p {
      font-size: 1.5rem !important;
    }
  }
}

///////////////////////////////////////
@media (max-width: 1400px) {
  .cards {
    margin-top: 3rem;
    text-align: center;
    gap: 3rem;
    .card-el {
      width: 40%;
      .icon {
        width: 80px;
      }
    }
  }
}

///////////////////////////////////////
@media (max-width: 1100px) {
  .cards {
    gap: 1.5rem;
    .card-el {
      p {
        font-size: 1.3rem !important ;
      }
    }
  }
}

///////////////////////////////////////
@media (max-width: 900px) {
  .cards {
    gap: 2.5rem;
    .card-el {
      width: 45%;
    }
  }
}

///////////////////////////////////////
@media (max-width: 767px) {
  .cards {
    .card-el {
      width: 80%;
      p {
        font-size: 1.2rem !important;
      }
    }
  }
}

///////////////////////////////////////
@media (max-width: 604px) {
  .cards {
    gap: 2rem;
    .card-el {
      width: 90%;
    }
  }
}

///////////////////////////////////////
@media (max-width: 470px) {
  .cards {
    .card-el {
      width: 90%;
      p {
        font-size: 1.1rem !important;
      }
    }
  }
}
</style>
