<!-- eslint-disable prettier/prettier -->

<template>
    <div>
      <div class="title" v-observe-visibility="slideToTop">
       <h1 class="header1">Contáctanos</h1>
       <p class="text-content">¿Buscas soluciones para capacitación, compliance, levantamiento de la información o analítica de datos?</p>
       <p class="text-content">¡Llámanos!</p>
       <p class="text-content">Tenemos una solución para cada una de tus necesidades.</p>
      </div>
      
      <v-container class="fluid mt-10" v-observe-visibility="slideToTop">
        <v-row class="justify-center">
          <v-col align="center">
            <b-img fluid :src="require('@/assets/contacto/contacto01.webp')" alt="content"></b-img>
          </v-col>
        </v-row>
      </v-container>
      <ContactoCards></ContactoCards>

      <div class="title">
       <h3 class="header3 text-left black--text mt-10">Llama o escribe a:</h3>
      </div>

      <v-form v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="name"
                required
                :rules="textRules"
                label="Nombre"
                outlined
                name = "name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="lastname"
                label="Apellido"
                outlined
                name="lastname"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="email"
                required
                :rules="emailRules"
                label="Email*"
                outlined
                name="email"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-textarea
                required
                :rules="textRules"
                v-model="message"
                outlined
                auto-grow
                label="Mensaje"
                name="message"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn
                color="#43baff"
                elevation="2"
                class="white--text mb-10"
                large
                @click="sendEmail"
              >Enviar</v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col>
              <div class="links">
                <b-icon-facebook></b-icon-facebook><b-icon-twitter></b-icon-twitter><b-icon-linkedin></b-icon-linkedin>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-form>


      <!--Snack de anuncio-->
      <v-snackbar
        v-model="snackbar"
        centered
        color="primary"
        timeout="2000"
      >
        {{ textConfirmation }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="black"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            X
          </v-btn>
        </template>
      </v-snackbar>

    </div>
  </template>

<script>
import ContactoCards from '@/components/contacto/ContactoCards.vue';

export default {
  /*
  metaInfo() {
    return {
      title: 'Contacto',
      meta: [
        {
          name: 'description',
          content:
            'Vanglar es una aplicación para estudiar diariamente diversos temas que te interesen para prepararte para una evaluación, un examen o una certificación.',
        },
        {
          property: 'og:title',
          content: 'Compliance',
        },
        { property: 'og:site_name', content: 'Vanglar' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    };
  },
  */
  data() {
    return {
      valid: false,
      buttonValid: false,
      name: '',
      lastname: '',
      message: '',
      textRules: [(v) => !!v || 'Necesario'],
      email: '',
      emailRules: [
        (v) => !!v || 'El correo es necesario',
        (v) => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
      textConfirmation: '',
      snackbar: false,
    };
  },
  methods: {
    slideToTop(isVisible, entry) {
      if (isVisible) {
        // trigger animation for the element
        let domElement = entry.target;
        domElement.classList.add('slideToTop');
      }
    },
    sendEmail() {
      if (this.name != '' && this.email != '' && this.message != '') {
        try {
          fetch('https://formsubmit.co/ajax/victor.quiroz@vanglar.com', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
            body: JSON.stringify({
              name: this.name,
              lastname: this.lastname,
              message: this.message,
              email: this.email,
            }),
          })
            .then((response) => response.json())
            .then((data) => {
              console.log(data.success);
              if (data.success == 'true') {
                this.textConfirmation = 'El correo fue enviado';
                this.snackbar = true;
              } else {
                this.textConfirmation = 'No se pudo enviar el correo';
                this.snackbar = true;
                this.clear();
              }
            });
        } catch (error) {
          console.log({ error });
        }
      } else {
        this.textConfirmation = 'No se pudo enviar el correo';
        this.snackbar = true;
      }
    },
    clear() {
      this.name = '';
      this.lastname = '';
      this.message = '';
      this.email = '';
    },
  },
  components: {
    ContactoCards,
  },
};
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  flex-direction: column;
  margin: 5rem auto 0 auto;
  width: 80%;
}

.links {
  display: flex;
  gap: 3rem;
  font-size: 1.5rem !important;
  color: #43baff;
  justify-content: right;
}

@media (max-width: 767px) {
  .title {
    width: 90%;
  }
}

@media (max-width: 470px) {
  .title {
    margin-top: 2rem;
  }
}
</style>
