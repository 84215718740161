<!-- eslint-disable prettier/prettier -->
<template>
  <div>
    <div class="title" v-observe-visibility="slideToTop">
      <h1 class="header1">Levantamiento de información</h1>
      <p class="text-content">Obtener información estratégica confiable, convertida en inteligencia de negocios en tiempo real, nunca ha sido tan fácil.</p>
    </div>
    
    <SlideComponent image="levantamiento/levantamiento1.png" :descriptionLeft=true height="800">
      <template v-slot:extra>
        <ul class="list">
          <li>
            <span>Recoge entrevistas, evidencias, posiciones geográficas con texto, opción múltiple, check box y firma.</span>
          </li>
          <li>
            <span>Herramientas inteligentes para reconocimiento facial, de imagen, de objetos o conversión de imagen a texto.</span>
          </li>
          <li>
            <span>Incluso en ambientes sin Internet, te ayudamos a tenerlo todo de forma expedita.</span>
          </li>
        </ul>
        <router-link :to="{name : 'contacto'}">
            <b-button size="lg" variant="primary">CONOCE MÁS</b-button>
        </router-link>
      </template>
    </SlideComponent>

    <SlideComponent title="Soluciones Vanglar" description="Nuestros servicios en levantamiento y procesamiento de información son un traje a la medida. La herramienta se adapta a cada organización." image="levantamiento/levantamiento2.png" :descriptionLeft=true height="1000">
      <template v-slot:extra>
        <p class="text-content">
          Vanglar resguarda la información, la encripta para su seguridad, y garantiza que siempre esté disponible.
        </p>
      </template>
    </SlideComponent>

    <LevantamientoCards></LevantamientoCards>

    <SlideComponent title="Analítica de datos" description="Una vez recopilada la información, dar el siguiente paso es fácil. Te ayudamos con el análisis de datos para identificar los casos por región, los flujos de casos de alto riesgo y otras tendencias sobre tu mercado y tus clientes." image="levantamiento/levantamiento3.png" :descriptionLeft=false height="1000">
      <template v-slot:extra>
        <p class="text-content">
          Vanglar cuenta con científicos de datos que apoyan, sin costo adicional, el desarrollo de tableros analíticos y servicios de análisis más sofisticados en el mercado.
        </p>
      </template>
    </SlideComponent>

    <SlideComponent title="Tableros de validación y correcciones" description="Ya sea para una mesa de control de calidad o permitir a tu equipo modificar la información, Vanglar te entrega tableros que automatizan estas tareas, guardan las versiones anteriores y mantienen una bitácora para saber cómo evolucionó la información." image="levantamiento/levantamiento4.png" :descriptionLeft=true height="1000">
    </SlideComponent>

    <SlideComponent title="App Vanglar" description="Garantiza la validez y confiabilidad de tu investigación con una herramienta sencilla que permite el despliegue y uso inmediatos para obtener datos de calidad." image="levantamiento/levantamiento5.png" :descriptionLeft=false height="1000">
    </SlideComponent>
  </div>
</template>

<script>
import SlideComponent from '@/components/general/SlideComponent.vue';
import LevantamientoCards from '@/components/levantamiento/LevantamientoCards.vue';

export default {
  /*
  metaInfo() {
    return {
      title: 'Levantamiento',
      meta: [
        {
          name: 'description',
          content:
            'Vanglar es una aplicación para estudiar diariamente diversos temas que te interesen para prepararte para una evaluación, un examen o una certificación.',
        },
        {
          property: 'og:title',
          content: 'Levantamiento',
        },
        { property: 'og:site_name', content: 'Vanglar' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    };
  },
  */
  components: {
    SlideComponent,
    LevantamientoCards,
  },
  methods: {
    slideToTop(isVisible, entry) {
      if (isVisible) {
        // trigger animation for the element
        let domElement = entry.target;
        domElement.classList.add('slideToTop');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  flex-direction: column;
  margin: 2rem auto 0 auto;
  width: 50%;
}

@media (max-width: 767px) {
  .title {
    width: 90%;
  }
}

@media (max-width: 470px) {
  .title {
    margin-top: 2rem;
  }
}
</style>
