<template>
  <div class="tag-input">
    <div v-for="(tag, index) in tags" :key="index" class="tag">
      <span @click="removeTag(index)">x</span>
      {{ tag }}
    </div>
    <input
      @keydown="addTag"
      @keydown.delete="removeLastTag"
      type="text"
      placeholder="Agrega una etiqueta"
      class="tag-text"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      tags: this.savedTags ? this.savedTags : [],
      //tags: this.savedTags,
    };
  },
  methods: {
    addTag(event) {
      if (event.code == 'Comma' || event.code == 'Enter') {
        event.preventDefault();
        let val = event.target.value.trim();
        if (val.length > 0) {
          this.tags.push(val);
          event.target.value = '';
        }
        this.$emit('inputChanged', this.tags);
      }
    },
    removeTag(index) {
      this.tags.splice(index, 1);
      this.$emit('inputChanged', this.tags);
    },
    removeLastTag() {
      if (event.target.value.length === 0) {
        this.removeTag(this.tags.length - 1);
        this.$emit('inputChanged', this.tags);
      }
    },
  },
  props: ['savedTags'],
};
</script>

<style lang="scss" scoped>
.tag-input {
  width: 100%;
  border: 1px solid #eee;
  font-size: 0.9rem;
  box-sizing: border-box;
  padding: 0 10px;
  margin: 1rem 0;
  .tag {
    height: 30px;
    float: left;
    margin-right: 10px;
    background-color: #eee;
    margin-top: 10px;
    line-height: 30px;
    padding: 0 5px;
    border-radius: 5px;
    span {
      cursor: pointer;
      opacity: 0.75;
    }
  }
  .tag-text {
    border: none;
    outline: none;
    font-size: 0.9rem;
    line-height: 50px;
    background: none;
  }
}
</style>
